import React, { useEffect, useRef, useState } from 'react'
import { Box, Flex, VStack, HStack, Text, Avatar, Input, Button, Divider, useColorModeValue, Image, Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, useDisclosure, CircularProgress, Spinner } from '@chakra-ui/react';
import axiosClient, { cloudfront, linkImage, socketUrl } from '../../axios-client';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import chatbg from '../../assets/images/chatbg.png'
import { useStateContext } from '../../context/ContextProvider';
function ChatRoom({ sender, recepteur, room, image, name }) {
    const { user } = useStateContext()
    const { t } = useTranslation()
    const chatBg = useColorModeValue('gray.100', 'gray.900');

    const chatBorderBg = useColorModeValue('white', 'gray.800');
    const borderColor = useColorModeValue('gray.200', 'gray.600');

    const chat1 = useColorModeValue('gray.200', 'gray.500');
    const chat2 = useColorModeValue('blue.100', 'blue.500');
    const [partenaire, setPartenaire] = useState([])
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedImage, setSelectedImage] = useState('');

    const [webSocket, setWebSocket] = useState(null);
    const [messages, setMessages] = useState([]);
    const [client, setClient] = useState();

    const chatContainerRef = useRef(null);
    const bottomRef = useRef(null);
    const [loading, setLoading] = useState(true)

    const handleImageClick = (imageSrc) => {
        setSelectedImage(imageSrc);
        onOpen();
    };
    useEffect(() => {
        axiosClient.get(`/partenaires/${sender}/`)
            .then(({ data }) => {
                setPartenaire(data)

            })
    }, [])

    useEffect(() => {
        axiosClient.get(`/clients/${recepteur}/`)
            .then(({ data }) => {
                setClient(data)
            })
        const socket = new WebSocket(`${socketUrl}/ws/chat/${room}/${sender}/${user.id}/`);

        socket.onopen = () => {
            console.log('WebSocket connected:', socket);
            setWebSocket(socket);
        };

        socket.onmessage = (event) => {
            const data = JSON.parse(event.data);
            if (data.type === 'fetch_messages') {
                setMessages(data.messages);
                setLoading(false)
            } else if (data.type === 'chat_message') {
                setMessages(prevMessages => [...prevMessages, {
                    sender: data.sender,
                    message: data.message,
                    date_creation: data.date_creation,
                    type_file: data.type_file,
                    file_url: data.file_url,
                    vu: false
                }]);
                setLoading(false)
            }
            console.log('Received data:', data);
        };

        socket.onclose = () => {
            console.log('WebSocket closed');
        };

        return () => {
            socket.close();
        };
    }, [room, recepteur, sender, user.id]);

    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [messages]);
    const dateOptions = { weekday: 'short', day: 'numeric', month: 'short', year: 'numeric' };
    const blend = useColorModeValue("", "overlay")

    return (
        <>
            <Box height={"100%"} flex="2" p="4" bg={chatBorderBg} w={{ base: '100%', md: 'auto' }}>
                <VStack align="start" h="100%">
                    <HStack
                        spacing="3"
                        p="2"
                        // py={2}
                        borderRadius="md"
                        w="100%"
                    >
                        <Avatar src={image} name={name} size="md" />
                        <Text fontSize="md">{name} </Text>
                    </HStack>

                    <Box
                        ref={chatContainerRef}
                        flex="1"
                        w="100%"
                        overflowY="auto"
                        p="4"
                        border="1px"
                        borderColor={borderColor}
                        borderRadius="md"
                        bg={chatBg}
                        bgImage={chatbg}
                        bgBlendMode={blend}

                    >
                        {loading ?
                            <Flex w={"100%"} h={"100%"} justify={"center"} align={"center"}>
                                <Spinner size='xl' speed='0.7s' thickness='5px' color='gray.500' />
                            </Flex>

                            :
                            <VStack pb={4} spacing="4" align="start">
                                {messages.map((msg, index) => {
                                    const a = msg.sender_id ? msg.sender_id : msg.sender;
                                    const isToday = new Date(msg.date_creation).toDateString() === new Date().toDateString();
                                    const showTodayDivider = isToday && (index === 0 || new Date(messages[index - 1].date_creation).toDateString() !== new Date().toDateString());

                                    return (
                                        <React.Fragment key={index}>
                                            {showTodayDivider && (
                                                <Text color="gray.500" alignSelf="center">
                                                    {t("Aujourd'hui")}
                                                </Text>
                                            )}
                                            {msg.type_file && (
                                                <HStack spacing="3" alignSelf={parseInt(a) === parseInt(sender) ? 'flex-end' : 'flex-start'} w={"60%"}>
                                                    {parseInt(a) !== parseInt(sender) && <Avatar bg={'gray.300'} src={image} name={name} size="sm" />}
                                                    <Flex w={"100%"} flexDirection={"column"}>
                                                        <Box w={"100%"} borderRadius="md">
                                                            <Image onClick={() => handleImageClick(msg.type_file.includes(cloudfront) ? msg.type_file : cloudfront + msg.type_file)} cursor={"pointer"} objectFit={'cover'} borderRadius="xl" w={"100%"} maxH={"280px"} src={msg.type_file.includes(cloudfront) ? msg.type_file : cloudfront + msg.type_file} />
                                                        </Box>
                                                        <Text textAlign={"end"} color={"gray.500"} fontSize={"12px"}>{isToday ? new Date(msg.date_creation).toLocaleTimeString(t('fr-FR')) : new Date(msg.date_creation).toLocaleString(t('fr-FR'), dateOptions)}</Text>
                                                    </Flex>
                                                    {parseInt(a) === parseInt(sender) && <Avatar bg={'blue.200'} src={partenaire?.image} name={partenaire?.last_name} size="sm" />}
                                                </HStack>
                                            )}
                                            {msg.message && (
                                                <HStack spacing="3" alignSelf={parseInt(a) === parseInt(sender) ? 'flex-end' : 'flex-start'} maxW={"60%"}>
                                                    {parseInt(a) !== parseInt(sender) && <Avatar mt={2} display={"flex"} alignSelf={"start"} bg={'gray.300'} src={image} name={name} size="sm" />}
                                                    <Flex w={"100%"} flexDirection={"column"}>
                                                        <Box w={"100%"} bg={parseInt(a) === parseInt(sender) ? chat2 : chat1} p="2.5" borderRadius="md">
                                                            <Text w={"100%"}>{msg.message}</Text>
                                                        </Box>
                                                        <Text textAlign={"end"} color={"gray.500"} fontSize={"12px"}>{isToday ? new Date(msg.date_creation).toLocaleTimeString(t('fr-FR')) : new Date(msg.date_creation).toLocaleString(t('fr-FR'), dateOptions)}</Text>
                                                    </Flex>
                                                    {parseInt(a) === parseInt(sender) && <Avatar mt={2} display={"flex"} alignSelf={"start"} bg={'blue.200'} src={partenaire?.image} name={partenaire?.last_name} size="sm" />}
                                                </HStack>
                                            )}
                                        </React.Fragment>
                                    );
                                })}
                                <div ref={bottomRef}></div>

                            </VStack>
                        }
                    </Box>


                </VStack>
            </Box>
            <Modal size={"xl"} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay
                    bg={"blackAlpha.700"}

                />
                <ModalContent>
                    <ModalBody p={0}>
                        <Image rounded={"md"} src={selectedImage} h={"100%"} w="100%" />
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

export default ChatRoom