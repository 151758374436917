import React, { useState, useEffect, useCallback } from 'react';
import axiosClient from '../../axios-client';
import { useNavigate } from 'react-router-dom';
import { SearchIcon } from '@chakra-ui/icons'
import MyPagination from "../../components/MyPagination";
import { useStateContext } from "../../context/ContextProvider";
import { AiOutlineUserAdd } from 'react-icons/ai';
import { LiaUserEditSolid } from 'react-icons/lia';

import { MdGroupRemove } from 'react-icons/md';
import { FiEdit3 } from 'react-icons/fi';

import {
    IconButton,
    Flex,
    Box,
    Button,
    Stack,
    Table,
    Thead,
    InputGroup,
    InputLeftElement,
    Tbody,
    Tr,
    Th,
    Select,
    Avatar,
    Switch,
    Badge,
    Td,
    Input,
    useToast,
    TableContainer,
    useColorModeValue,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Tooltip,
    Text,
    SkeletonCircle,
    Skeleton,


} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export const GestAdmins = () => {
    const [loadingData, setLoadingData] = useState(true)

    const [fullName, setFullName] = useState()
    const [oldRole, setOldRole] = useState()
    const [newRole, setNewRole] = useState()
    const [selectedRole, setSelectedRole] = useState()
    const toast = useToast()
    const [selectedEtat, setSelectedEtat] = useState(null);
    const [selectedUser, setSelectedUser] = useState('');
    const { t } = useTranslation();
    const [paginationData, setPaginationData] = useState()
    const [currentPage, setCurrentPage] = useState(0);

    //search variable
    const [searchTerm, setSearchTerm] = useState('');

    const navigate = useNavigate()

    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);


    const onOpenAlert = () => {
        setIsAlertOpen(true);
    };

    const onOpenModal = () => {
        setIsModalOpen(true);
    };

    const onCloseAlert = () => {
        setIsAlertOpen(false);
    };

    const onCloseModal = () => {
        setIsModalOpen(false);
    };

    const MAX_WORDS = 2; // Maximum number of words to display

    function truncateAddress(address) {
        const words = address.split(' ');
        if (words.length > MAX_WORDS) {
            return words.slice(0, MAX_WORDS).join(' ') + '...';
        }
        return address;
    }
    ////////////////////////////////the size of the table//////////////////////////////
    const PAGE_SIZE = 10;
    const [pageSize, setPageSize] = useState(PAGE_SIZE);
    const handlePageSizeChange = (event) => {
        const newSize = parseInt(event.target.value, 10);
        setPageSize(newSize);
        setCurrentPage(0);
    };
    //////////////////////////////////////////////////////////////////////////////////

    // current user
    const { user, setUser } = useStateContext();

    const [deleteId, setDeleteId] = useState(null);
    const cancelRef = React.useRef()

    //Apprenant data
    const [data, setData] = useState([]);
    const [checkLengthData, setCheckLengthData] = useState(false)


    // current user data
    useEffect(() => {
        axiosClient.get('auth/user/')
            .then(({ data }) => {
                setUser(data)
            })
    }, [])

    //get the apprenant data
    useEffect(() => {
        // setLoadingData(true)
        axiosClient.get(`/admins/getAllAdmins/?page_size=${pageSize}&page=${currentPage + 1}&search_term=${searchTerm}&etat=${selectedEtat}&role=${selectedUser}`)
            .then((response) => {


                // if (selectedUser !== '') {
                //     filteredData = filteredData.filter((item) => item.role === selectedUser);
                // }
                let filteredData = response.data.results;
                setPaginationData(response.data);
                // if (selectedEtat !== null) {
                //     filteredData = filteredData.filter((item) => item.etat === selectedEtat);
                // }
                if (filteredData.length === 0) {
                    setCheckLengthData(true)
                }
                else {
                    setData(filteredData);
                }
                setLoadingData(false)
            })
            .catch((error) => {
                console.error(error);
                setLoadingData(false)
            });
    }, [user, selectedUser, selectedEtat, pageSize, currentPage, searchTerm]);


    const handleRoleSubmit = () => {
        changeRole(deleteId, selectedRole);
        setIsModalOpen(false);
    };

    const [message, setMessage] = useState(false)





    const changeRole = (id) => {
        const updatedData = data.map(row => {
            if (row.id === id) {
                const formData = new FormData();

                formData.append("role", selectedRole);
                formData.append("edited_by", user.first_name + ' ' + user.last_name);

                axiosClient.put(`/update_admin_role/${id}/`, formData)
                    .then((response) => {
                        // const updatedRow = response.data;
                        // Update the row object with the new etat value
                        setData(rows => rows.map(row => {
                            if (row.id === id) {
                                return {
                                    ...row,
                                    role: selectedRole,
                                    // date_modification: (updatedRow.date_modification).format('YYYY-MM-DD HH:mm:ss'),
                                    // edited_by: updatedRow.edited_by || (user.first_name + ' ' + user.last_name),
                                };
                            }
                            return row;
                        }));
                        toast({
                            description: "le role est changé avec succès",
                            status: 'success',
                            duration: 2000,
                            variant: 'left-accent',
                            isClosable: true,
                            position: 'bottom-right'
                        })
                    })

                    .catch((error) => console.error(error));

            }
        });
    }

    const handleDelete = (id = deleteId) => {
        if (!id) return;
        axiosClient.delete(`/admins/${id}/`)
            .then((response) => {
                setData((prevData) => prevData.filter((row) => row.id !== id));
                toast({
                    description: t("l'utilisateur est supprimé avec succès"),
                    status: 'success',
                    variant: 'left-accent',
                    duration: 2000,
                    isClosable: true,
                    position: 'bottom-right'
                })
            })
            .catch((error) => {
                console.error(error);
            });
        onCloseAlert()
    }

    //search method
    // const filteredData = useCallback(() => {
    //     return data.filter((row) => {
    //         // Exclude data of current user
    //         if (row.id === user.id || row.role === "SUPERADMIN") {
    //             return false;
    //         }

    //         if (row.role === "ADMIN" && (user.role !== "SUPERADMIN")) {
    //             return false;
    //         }

    //         return (
    //             row.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //             row.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //             row.code.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //             row.pays.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //             row.ville.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //             row.rue.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //             row.code_postal.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //             row.email.toLowerCase().includes(searchTerm.toLowerCase())
    //         );
    //     });
    // }, [data, searchTerm, user.id]);


    //edit l'etat de responsable ('activé','désactivé')
    const handleEtatClick = (id, etat) => {
        const formData = new FormData();
        formData.append("etat", !etat);
        formData.append("edited_by", user.first_name + ' ' + user.last_name);

        axiosClient.put(`/update_admin_etat/${id}/`, formData)
            .then((response) => {
                const updatedRow = response.data;
                // Update the row object with the new etat value
                setData(rows => rows.map(row => {
                    if (row.id === id) {
                        return {
                            ...row,
                            etat: !etat,
                            // date_modification: updatedRow.date_modification.format('YYYY-MM-DD HH:mm:ss'),
                            // edited_by: updatedRow.edited_by || (user.first_name + ' ' + user.last_name),
                        };
                    }
                    return row;
                }));
            })
            .catch((error) => console.error(error));
        if (!etat) {
            toast({
                description: t("le compte est activé"),
                variant: 'left-accent',
                status: 'success',
                duration: 2000,
                isClosable: true,
                position: 'bottom-right'
            })
        }
        else {
            toast({
                description: t("le compte est désactivé"),
                status: 'success',
                variant: 'left-accent',
                duration: 2000,
                isClosable: true,
                position: 'bottom-right'
            })
        }
    }


    return (
        <Box mt="5px" >
            <Flex>
                <Box w="100%" display={"flex"} justifyContent="space-between" flexDirection={{ base: 'column', md: "row" }} mt={2} mb={{ base: 0, md: 5 }}>
                    <Text fontFamily='Plus Jakarta Display' as="cite" fontSize={{ base: "18px", md: "20px", lg: "25px" }} color="gray"><span onClick={() => navigate('/')} style={{ cursor: 'pointer' }} onMouseEnter={e => e.target.style.textDecoration = "underline"} onMouseLeave={e => e.target.style.textDecoration = "none"} >{t("Accueil")}</span>  / <span  >{t("Administrateurs")} </span></Text>
                    <Button onClick={() => navigate('/ajouter-admin')} leftIcon={<AiOutlineUserAdd color='#49b1cc' />} colorScheme='gray' variant='solid'>
                        {t("Ajouter administrateur")}
                    </Button>
                </Box>

            </Flex>


            <Flex
                align={'center'}
                justify={'center'}
            >

                <Stack

                    w={'full'}
                    maxW='full'
                    bg={useColorModeValue('white', 'gray.700')}
                    rounded={'lg'}
                    p={6}
                    my={5}>
                    {/* Search input */}
                    <Flex
                        justifyContent="space-between"
                        alignItems="center"
                        direction={{ base: 'column', lg: 'row' }}
                    >
                        <Flex w={"100%"}>
                            <Select borderRadius="5" size="sm" fontSize={{ base: 13, lg: 16 }} w={{ base: '70%', lg: '50%' }} mr={5} mb={4} value={selectedUser} onChange={(e) => setSelectedUser(e.target.value)}>
                                <option value="">{t("Tout les utilisateurs")}</option>
                                <option value="ADMIN">{t("Administrateur")}</option>
                                <option value="RESPO">{t("Responsable")}</option>
                                <option value="AGENTSUPPORT">{t("Agent de support client")} </option>
                                <option value="RESPOEVENTS">{t("Responsable des événements")}</option>
                            </Select>
                            <Select borderRadius="5" size="sm" fontSize={{ base: 13, lg: 16 }} w={{ base: '30%', lg: "20%" }} mb={4} value={selectedEtat} onChange={(e) => setSelectedEtat(e.target.value === '' ? null : e.target.value === 'true')}>
                                <option value="">{t("Tout")}</option>
                                <option value="true">{t("Activé")}</option>
                                <option value="false">{t("Désactivé")}</option>
                            </Select>
                        </Flex>


                        <InputGroup size="sm" w={{ base: '100%', lg: "40%" }}>
                            <InputLeftElement
                                pointerEvents='none'
                                children={<SearchIcon color='gray.300' />}
                            />
                            <Input
                                borderRadius="5"
                                placeholder={t("Recherche...")}
                                value={searchTerm}
                                onChange={(e) => {
                                    setCurrentPage(0)
                                    setSearchTerm(e.target.value)
                                }}
                                mb={4}
                                sx={{
                                    marginLeft: 'auto',
                                }}
                            />  </InputGroup>

                    </Flex>
                    <TableContainer>
                        <Table size="sm" variant='striped' colorScheme="gray">
                            <Thead size="sm">
                                <Tr fontSize="12">
                                    <Th fontFamily='Plus Jakarta Display' fontSize="12">Image</Th>
                                    <Th fontFamily='Plus Jakarta Display' fontSize="12">Code</Th>
                                    <Th fontFamily='Plus Jakarta Display' fontSize="12">{t("Nom")}</Th>
                                    <Th fontFamily='Plus Jakarta Display' fontSize="12">{t("Prénom")}</Th>
                                    <Th fontFamily='Plus Jakarta Display' fontSize="12"> Email</Th>
                                    <Th fontFamily='Plus Jakarta Display' fontSize="12">{t("Adresse")}</Th>
                                    <Th fontFamily='Plus Jakarta Display' fontSize="12">{t("Pays")}</Th>
                                    <Th fontFamily='Plus Jakarta Display' fontSize="12">{t("État")}</Th>
                                    <Th fontFamily='Plus Jakarta Display' fontSize="12">Role</Th>
                                    <Th fontFamily='Plus Jakarta Display' fontSize="12">{t("Date Création")}</Th>
                                    {(user.role === "SUPERADMIN" || user.role === "ADMIN") &&
                                        <Th fontFamily='Plus Jakarta Display' fontSize="12">Options</Th>
                                    }
                                </Tr>
                            </Thead>
                            <Tbody>
                                {loadingData &&
                                    <Tr>
                                        <Td><SkeletonCircle size='10' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>
                                    </Tr>}

                                {(paginationData?.length !== 0) &&
                                    data.map((row) => (
                                        <Tr key={row.id}>
                                            <Td > <Avatar size='md' src={row.image} /></Td>
                                            <Td >{row.code}</Td>
                                            <Td>{row.last_name}</Td>
                                            <Td>{row.first_name}</Td>
                                            <Td >{row.email}</Td>
                                            <Td>
                                                <Tooltip label={row.rue + " " + row.ville + " " + row.code_postal} closeOnClick={true}>
                                                    {truncateAddress(row.rue + " " + row.ville + " " + row.code_postal)}
                                                </Tooltip>
                                            </Td>
                                            <Td>{row.pays}</Td>
                                            <Td>
                                                <Flex>
                                                    <Stack direction='row' mr="3px">
                                                        {(user.role === "SUPERADMIN" || user.role === "ADMIN") &&
                                                            <Switch
                                                                colorScheme='green'
                                                                isChecked={row.etat}
                                                                onChange={() => handleEtatClick(row.id, row.etat)}
                                                            />
                                                        }
                                                    </Stack>

                                                    <Badge
                                                        size="sm"
                                                        rounded={'lg'}
                                                        colorScheme={!row.etat ? 'red' : 'green'}
                                                    >
                                                        {row.etat ? t("Activé") : t("Désactivé")}

                                                    </Badge>
                                                </Flex>
                                            </Td>
                                            {(user.role === "SUPERADMIN" || user.role === "ADMIN") ?
                                                <Td w={"100%"} p={0}>
                                                    <Badge
                                                        display="flex"
                                                        alignItems="center"
                                                        justifyContent="center"
                                                        rounded="full"
                                                        w={"fit-content"}
                                                        py={2}
                                                        // colorScheme={{user.role === "SUPERADMIN"? "": ""}}
                                                        px={4}
                                                        cursor={(user.role === "SUPERADMIN" || user.role === "ADMIN") ? "pointer" : null}
                                                        title={(user.role === "SUPERADMIN" || user.role === "ADMIN") ? "changer le rôle de l'utilisateur" : null}
                                                        onClick={() => {
                                                            if ((user.role === "SUPERADMIN" || user.role === "ADMIN")) {
                                                                setFullName(`${row.first_name} ${row.last_name}`)
                                                                setSelectedRole(row.role)
                                                                setDeleteId(row.id);
                                                                setIsModalOpen(true);

                                                            }
                                                        }}>
                                                        <LiaUserEditSolid size={20} />
                                                        <Text ml={1}>{row.role === "ADMIN" ? t("Administrateur") : row.role === "RESPO" ? t("Responsable") : row.role === "AGENTSUPPORT" ? t("Agent de support client") : row.role === "RESPOEVENTS" ? t("Responsable des événements") : null}</Text>
                                                    </Badge>
                                                </Td>
                                                : <Td> {row.role.toLowerCase() === 'admin' ? t("Administrateur") : row.role.toLowerCase() === 'respo' ? t("Responsable") : row.role.toLowerCase() === 'master' ? t('Super administrateur') : row.role.toLowerCase() === 'agentsupport' ? t("Agent de support client") : row.role.toLowerCase() === 'respoevents' ? t('Responsable des événements') : 'null'} </Td>
                                            }
                                            <Td>{new Date(row.date_creation).toLocaleDateString(t("fr-FR"))}</Td>
                                            <Td>

                                                <IconButton
                                                    size="sm"
                                                    onClick={() => navigate(`/modifier-admin`, {
                                                        state: {
                                                            idAdmin: row.id
                                                        }
                                                    })}
                                                    mr="5px"
                                                    variant={'outline'}
                                                    colorScheme="whatsapp"
                                                    icon={<FiEdit3 />}>
                                                </IconButton>
                                                {/* <Popover placement="left" >
                                                <PopoverTrigger>
                                                    <IconButton
                                                        size="sm"
                                                        mr="5px"
                                                        bg="gray.300"
                                                        color={"black"}
                                                        icon={<MdHistory />}>
                                                    </IconButton>
                                                </PopoverTrigger>
                                                <PopoverContent w={"full"} color="black" bgGradient='linear(to-r, white, #497dff)' >
                                                    <PopoverHeader letterSpacing={1.5} pt={4} fontWeight="bold" border="0">
                                                        Historique
                                                    </PopoverHeader>
                                                    <PopoverArrow bg="#497dff" />
                                                    <PopoverCloseButton />
                                                    <PopoverBody>
                                                        <div style={{ marginBottom: '8px' }}>
                                                            Ajouté par : {row.added_by}
                                                        </div>
                                                        <div style={{ marginBottom: '8px' }}>
                                                            Modifié par : {row.edited_by}
                                                        </div>

                                                        <div>
                                                            Date de dernière modification: {new Date(row.date_modification).toLocaleString('fr-FR')}
                                                        </div>
                                                    </PopoverBody>
                                                    <PopoverHeader pt={4} fontWeight="bold" border="0">
                                                    </PopoverHeader>
                                                </PopoverContent>
                                            </Popover> */}
                                                {(user.role === "SUPERADMIN" || user.role === "ADMIN") &&

                                                    <IconButton
                                                        size="sm"
                                                        onClick={() => {
                                                            setDeleteId(row.id);
                                                            setSelectedRole(row.role === "ADMIN" ? t("Administrateur") : row.role === "RESPO" ? t("Responsable") : row.role === "AGENTSUPPORT" ? t("Agent de support client") : row.role === "RESPOEVENTS" ? t("Responsable des événements") : null)
                                                            onOpenAlert();

                                                        }}
                                                        mr="5px"
                                                        colorScheme="red"
                                                        icon={<MdGroupRemove />}>
                                                    </IconButton>}
                                            </Td>

                                        </Tr>
                                    ))}
                                {(((searchTerm || selectedEtat !== null || selectedRole !== null) && paginationData?.length === 0) || (checkLengthData)) && (
                                    <Tr>
                                        <Td colSpan={11}>{t("Aucune ligne correspondante n'a été trouvée.")}</Td>
                                    </Tr>
                                )}
                            </Tbody>
                        </Table>

                    </TableContainer>
                    {/* Pagination */}
                    <Flex flexDirection={{ base: 'column', lg: "row" }} justify="space-between" align="center" w="100%">
                        <Box flex="1">
                            <MyPagination
                                paginationData={paginationData}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                PAGE_SIZE={pageSize}
                            />
                        </Box>

                        <Select w="70px" value={pageSize} onChange={handlePageSizeChange}>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="40">40</option>
                            <option value="50">50</option>
                            {/* Add more options as needed */}
                        </Select>
                    </Flex>
                </Stack>
            </Flex>
            <AlertDialog
                closeOnOverlayClick={false}
                isOpen={isAlertOpen}
                leastDestructiveRef={cancelRef}
                onClose={onCloseAlert}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            {t('Supprimer')} {selectedRole}
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            <Text>
                                {t('êtes-vous sûr ? Vous ne pourrez pas annuler cette action ultérieurement.')}
                            </Text>
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button size={"sm"} ref={cancelRef} onClick={() => {
                                setMessage(false)
                                onCloseAlert()
                            }}>
                                {t('Annuler')}
                            </Button>
                            <Button size={"sm"} onClick={() => handleDelete(deleteId)} colorScheme='red' ml={3} isDisabled={message}>
                                {t('Supprimer')}
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
            <AlertDialog

                size={"lg"}
                closeOnOverlayClick={false}
                isOpen={isModalOpen}
                leastDestructiveRef={cancelRef}
                onClose={onCloseModal}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            {t("Modifier le rôle de")} {fullName}
                        </AlertDialogHeader>

                        <AlertDialogBody >

                            <Stack>
                                <Text mb={2} fontSize={"14px"}>
                                    {t("Êtes-vous sûr de vouloir changer le rôle de cet utilisateur ? Changer le rôle mettra à jour ses privilèges et peut affecter sa capacité à effectuer certaines actions.")}
                                </Text>
                                <Select
                                    size={"sm"}
                                    defaultValue={selectedRole} onChange={e => setSelectedRole(e.target.value)}>
                                    {/* {(user.role === "SUPERADMIN" || user.role === "MASTER") &&
                                        <option value="MASTER">Super-administrateur</option>
                                    } */}
                                    <option value="ADMIN">{t("Administrateur")}</option>
                                    <option value="RESPO">{t("Responsable")}</option>
                                    <option value="AGENTSUPPORT">{t("Agent de support client")} </option>
                                    <option value="RESPOEVENTS">{t("Responsable des événements")}</option>
                                </Select>
                            </Stack>


                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button size={"sm"} ref={cancelRef} onClick={() => {
                                setMessage('')
                                setNewRole(oldRole)
                                onCloseModal()
                            }}>
                                {t("Annuler")}
                            </Button>
                            <Button size={"sm"} onClick={handleRoleSubmit} colorScheme='green' ml={3} isDisabled={message}>
                                {t("Modifier")}
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>

        </Box>

    )
}

export default GestAdmins