import axios from "axios";

export const domainName = "https://api.apapremium.com"

// export const linkImage = 'http://127.0.0.1:8000'
// export const linkImage = 'http://192.168.100.5:8000'
export const linkImage = ""

// export const socketUrl = 'ws://127.0.0.1:8000'
// export const socketUrl = 'ws://192.168.100.5:8000'
export const socketUrl = 'wss://api.apapremium.com'

// export const cloudfront = "http://127.0.0.1:8000/media/"
// export const cloudfront ="http://192.168.100.5:8000/media/"
export const cloudfront = "https://d2za39ka8iz25w.cloudfront.net/"


const axiosClient = axios.create({
  // baseURL: "http://127.0.0.1:8000/",
  // baseURL: "http://192.168.100.5:8000/",
  baseURL: "https://api.apapremium.com/",


});

axiosClient.interceptors.request.use((config) => {
  const token = localStorage.getItem("ACCESS_TOKEN");
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});


export default axiosClient;
