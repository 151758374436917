import React, { useState, useEffect, useCallback } from 'react';
import axiosClient from '../../axios-client';
import { AiFillDelete } from 'react-icons/ai';
import { SearchIcon } from '@chakra-ui/icons'
import MyPagination from "../../components/MyPagination";
import { BiDetail } from 'react-icons/bi';
import { MdOutlineQuestionAnswer } from 'react-icons/md';
import moment from 'moment';


import {
  Flex,
  Box,
  Heading,
  Button,
  Stack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  InputGroup,
  HStack,
  Td,
  TableContainer,
  useColorModeValue,
  InputLeftElement,
  Badge,
  useToast,
  Input,

  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Select,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Text,
  FormControl,
  FormLabel,
  Textarea,
  Popover,
  PopoverTrigger,
  IconButton,
  PopoverContent,
  PopoverHeader,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  Image,
  Alert,
  AlertIcon,
  Skeleton,
} from '@chakra-ui/react';

import { Switch } from '@chakra-ui/react';
import { useStateContext } from "../../context/ContextProvider";
import { MdHistory } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


export const GestTickets = () => {
  const {countTickets , setCountTickets} = useStateContext();

  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isImgOpen, setIsImgOpen] = useState(false);
  const navigate = useNavigate()
  const { t } = useTranslation();

  const [reponseUser, setReponseUser] = useState('')
  const [img, setImg] = useState('')

  const [resUserByWho, setResUserByWho] = useState('')
  const [userTime, setUserTime] = useState('')
  const [resByWho, setResByWho] = useState('')
  const [time, setTime] = useState('')

  const onOpenAlert = () => {
    setIsAlertOpen(true);
  };
  const onOpenImg = () => {
    setIsImgOpen(true);
  };

  const onOpenModal = () => {
    setIsModalOpen(true);
  };

  const onCloseAlert = () => {
    setIsAlertOpen(false);
    setDeleteId(null)
  };

  const onCloseImg = () => {
    setIsImgOpen(false);
  };

  const onCloseModal = () => {
    setIsModalOpen(false);
  };
  ////////////////////////////////the size of the table//////////////////////////////
  const PAGE_SIZE = 10;
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const handlePageSizeChange = (event) => {
    const newSize = parseInt(event.target.value, 10);
    setPageSize(newSize);
    setCurrentPage(0);
  };
  //////////////////////////////////////////////////////////////////////////////////
  const [deleteId, setDeleteId] = useState(null);
  const [reponse, setReponse] = useState('');
  const [sujet, setSujet] = useState('');
  const [content, setContent] = useState('');


  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = React.useRef()
  //toast variable
  const toast = useToast()

  //search variable
  const [searchTerm, setSearchTerm] = useState('');

  //plainte data
  const [data, setData] = useState([]);
  //selected plainte
  const [dataDetail, setDataDetail] = useState('');

  //user data
  // const [userData, setUserData] = useState([]);
  const [adminData, setAdminData] = useState([]);
  const [respoData, setRespoData] = useState([]);
  const [count, setCount] = useState([]);
  const [countr, setCountr] = useState([]);
  const [countp, setCountp] = useState([]);


  const [datac, setDatac] = useState([]);
  const [dataClient, setDataClient] = useState([]);
  const [dataPartenaire, setDataPartenaire] = useState([]);

  const [countc, setCountc] = useState([]);

  const { user, setUser } = useStateContext();

  const [currentPage, setCurrentPage] = useState(0);

  const [selectedEtat, setSelectedEtat] = useState(false);

  const [selectedCategory, setSelectedCategory] = useState('');


  const [checkLengthData, setCheckLengthData] = useState(false)
  const [loadingData, setLoadingData] = useState(true)
  const [paginationDataCl, setPaginationDataCl] = useState()
  const [paginationDataPr, setPaginationDataPr] = useState()

  const [dataClients, setDataClients] = useState([]);
  const [dataPartenaires, setDataPartenaires] = useState([]);

  // current user data
  useEffect(() => {
    axiosClient.get('auth/user/')
      .then(({ data }) => {
        setUser(data)
      })
  }, [])


  //get the plainte data
  useEffect(() => {
    // setLoadingData(true)

      axiosClient.get(`/tickets/getAllTicketsClients/?page_size=${pageSize}&page=${currentPage+1}&search_term=${searchTerm}&etat=${selectedEtat}&cat=${selectedCategory}`)
        .then((response) => {
          let filteredData = response.data.results;
          setPaginationDataCl(response.data);
          
          if (filteredData.length === 0) {
            setCheckLengthData(true)
          }
          else {
            setDataClients(filteredData);
          }
          setLoadingData(false)
        })
        .catch((error) => {
          console.error(error);
          setLoadingData(false)
        });

      axiosClient.get('/tickets/getPartenairesTicketsCount/') 
          .then((res) => {
            console.log("okkk",res);
            setCountp(res.data);
          })
          .catch((error) => {
            console.error(error);
          });
      
      axiosClient.get('/tickets/getClientsTicketsCount/') 
          .then((res) => {
            console.log("okkk",res);
            setCountc(res.data);
          })
          .catch((error) => {
            console.error(error);
          });  
      axiosClient.get('/tickets/getTotalTicketsCount/') 
          .then((res) => {
            setCountTickets(res.data);
          })
          .catch((error) => {
            console.error(error);
          });    


      axiosClient.get(`/tickets/getAllTicketsPartenaires/?page_size=${pageSize}&page=${currentPage+1}&search_term=${searchTerm}&etat=${selectedEtat}&cat=${selectedCategory}`)
      .then((response) => {
        let filteredData = response.data.results;
        setPaginationDataPr(response.data);
        
        if (filteredData.length === 0) {
          setCheckLengthData(true)
        }
        else {
          setDataPartenaires(filteredData);
        }

        setLoadingData(false)
      })
      .catch((error) => {
        console.error(error);
        setLoadingData(false)
      });
  }, [selectedEtat, selectedCategory,pageSize, currentPage,searchTerm,selectedEtat , countp]);


  function update() {
    // setLoadingData(true)

      axiosClient.get(`/tickets/getAllTicketsClients/?page_size=${pageSize}&page=${currentPage+1}&search_term=${searchTerm}&etat=${selectedEtat}&cat=${selectedCategory}`)
        .then((response) => {
          let filteredData = response.data.results;
          setPaginationDataCl(response.data);
          
          if (filteredData.length === 0) {
            setCheckLengthData(true)
          }
          else {
            setDataClients(filteredData);
          }
          setLoadingData(false)
        })
        .catch((error) => {
          console.error(error);
          setLoadingData(false)
        });

      axiosClient.get('/tickets/getPartenairesTicketsCount/') 
          .then((res) => {
            console.log("okkk",res);
            setCountp(res.data);
          })
          .catch((error) => {
            console.error(error);
          });
      
      axiosClient.get('/tickets/getClientsTicketsCount/')
          .then((res) => {
            console.log("okkk",res);
            setCountc(res.data);
          })
          .catch((error) => {
            console.error(error);
          });    

      axiosClient.get('/tickets/getTotalTicketsCount/') 
          .then((res) => {
            setCountTickets(res.data);
          })
          .catch((error) => {
            console.error(error);
          });  

      axiosClient.get(`/tickets/getAllTicketsPartenaires/?page_size=${pageSize}&page=${currentPage+1}&search_term=${searchTerm}&etat=${selectedEtat}&cat=${selectedCategory}`)
      .then((response) => {
        let filteredData = response.data.results;
        setPaginationDataPr(response.data);
        
        if (filteredData.length === 0) {
          setCheckLengthData(true)
        }
        else {
          setDataPartenaires(filteredData);
        }

        setLoadingData(false)
      })
      .catch((error) => {
        console.error(error);
        setLoadingData(false)
      });
  }

  function formatDateToCustomFormat(dateString) {
    const date = new Date(dateString);
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  //get partenaires data
  useEffect(() => {
    axiosClient.get('/partenaires/')
      .then((response) => {
        setDataPartenaire(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  //get clients data
  useEffect(() => {
    axiosClient.get('/clients/')
      .then((response) => {
        setDataClient(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  //get the admin data
  useEffect(() => {
    axiosClient.get('/admins/')
      .then((response) => {
        setAdminData(response.data);
        setRespoData(response.data)
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);


  //delete plainte
  // const handleDelete = (id = deleteId) => {
  //   if (!id) return;
  //   axiosClient.delete(`/tickets/${id}/`)
  //     .then((response) => {
  //       setData((prevData) => prevData.filter((row) => row.id !== id));
  //       toast({
  //         description: "le ticket est supprimé avec succès",
  //         status: 'warning',
  //         duration: 2000,
  //         isClosable: true,
  //         position: 'bottom-right'
  //       })
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  //   onCloseAlert()
  // }

  const [plainteData, setPlainteData] = useState({ content: "", sujet: "", etat: 0, user: "", response: "" });
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setPlainteData((prevUserData) => ({
      ...prevUserData,
      response: value,
    }));
  };

  const [message, setMessage] = useState('')

  //send response
  const sendResponse = (id = deleteId) => {
    if (!id) return;
    if (plainteData.response.trim() === "") {
      setMessage(t("Veuillez saisir une réponse"));
      return;
    }
    function formatDateTime(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }
    const updatedPlainteData = {
      response: plainteData.response,
      edited_by: user.first_name + ' ' + user.last_name,
      responsebywho: user.first_name + ' ' + user.last_name,
      responsetime: formatDateTime(new Date()),
      is_edited :"True"
    };
    axiosClient.patch(`/tickets/${deleteId}/`, updatedPlainteData)
      .then(() => {
        toast({
          description: t("votre réponse est envoyée avec succès"),
          status: 'success',
          duration: 2000,
          isClosable: true,
          variant: "left-accent",
          position: 'bottom-right'
        })
        update()
        setMessage("")
      }
      )
      .catch(error => console.error(error));
    onCloseAlert()
  }

  //get plainte
  useEffect(() => {
    axiosClient.get(`/tickets/${deleteId}/`)
      .then((response) => {
        setDataDetail(response.data.sort().reverse());
      })
      .catch((error) => {
        console.error(error);
      });
  }, [deleteId, dataDetail.response]);

  //edit the plainte state ('encours','Réglée')
  const handleEtatClick = (id, etat) => {
    const formData = new FormData();
    formData.append("etat", !etat);
    formData.append("edited_by", user.first_name + ' ' + user.last_name);
    axiosClient.patch(`/tickets/${id}/`, formData)
      .then((response) => {
        const updatedRow = response.data;
        // Update the row object with the new etat value
        setDataClients(rows => rows.map(row => {
          if (row.id === id) {
            return {
              ...row,
              etat: !etat,
              date_modification: moment(updatedRow.date_modification).format('YYYY-MM-DD HH:mm:ss'),
              edited_by: updatedRow.edited_by || (user.first_name + ' ' + user.last_name),
            };
          }
          return row;
        }));
        setDataPartenaires(rows => rows.map(row => {
          if (row.id === id) {
            return {
              ...row,
              etat: !etat,
              date_modification: moment(updatedRow.date_modification).format('YYYY-MM-DD HH:mm:ss'),
              edited_by: updatedRow.edited_by || (user.first_name + ' ' + user.last_name),
            };
          }
          return row;
        }));
        update()
        setMessage("")
        console.error(response);
      })
      .catch((error) => console.error(error));
    if (!etat) {
      toast({
        description: t("le ticket est réglé"),
        status: 'success',
        duration: 2000,
        isClosable: true,
        variant: "left-accent",
        position: 'bottom-right'
      })

    }
    else {
      toast({
        description: t("le ticket est encours"),
        status: 'warning',
        duration: 2000,
        isClosable: true,
        variant: "left-accent",
        position: 'bottom-right'
      })
    }
  }


  const viderInputs = () => {
    setSearchTerm('')
    setSelectedCategory('')
    // setSelectedEtat('')
  }

  const bg = useColorModeValue("white", "gray.700");

  const formatDate = (dateString) => {
    if (dateString) {
      const [year, month, day] = dateString.split('-');
      return `${day}-${month}-${year}`;
    }

  };
  const color = useColorModeValue("black", "white");
  const txtcolor = useColorModeValue("white", "black");

  return (
    <Box mt="5px" >

      <Flex>
        <Box w="90%" mt={2} mb={5}>
          <Text fontFamily='Plus Jakarta Display' as="cite" fontSize={{ base: "18px", md: "20px", lg: "25px" }} color="gray"><span onClick={() => navigate('/')} style={{ cursor: 'pointer' }} onMouseEnter={e => e.target.style.textDecoration = "underline"} onMouseLeave={e => e.target.style.textDecoration = "none"} >{t("Accueil")}</span>  / <span >{t("Propositions d'amélioration")}</span></Text>
        </Box>
      </Flex>
      <Stack>
        <Tabs variant="enclosed">
          <TabList overflowX={'auto'} overflowY={'hidden'} css={{
            /* Hide scrollbar */
            "&::-webkit-scrollbar": {
              display: "none",
            },
            /* Optional: Firefox scrollbar */
            scrollbarWidth: "none",
            msOverflowStyle: "none",
          }}>

            {/* {(user.role === "SUPERADMIN" || user.role === "ADMIN") &&

              <Tab _selected={{ color: txtcolor, bg: color }} onClick={viderInputs}>
                <Text fontSize={{ base: 12, lg: 15 }}>Administrateurs
                  {count > 0 && (
                    <Badge rounded="full" bg="#df2e38" style={{ padding: "2px 7px 2px 6px" }} color="white" ml='2'>
                      {count}
                    </Badge>
                  )}
                </Text>
              </Tab>} */}
            {/* {(user.role === "SUPERADMIN" || user.role === "ADMIN") &&

              <Tab _selected={{ color: txtcolor, bg: color }} onClick={viderInputs}>
                <Text fontSize={{ base: 12, lg: 15 }}>Responsables
                  {countr > 0 && (
                    <Badge rounded="full" bg="#df2e38" style={{ padding: "2px 7px 2px 6px" }} color="white" ml='2'>
                      {countr}
                    </Badge>
                  )}</Text>
              </Tab>} */}
            {(user.role === "SUPERADMIN" || user.role === "ADMIN" || user.role === "RESPO" || user.role === "AGENTSUPPORT") &&

              <Tab _selected={{ color: txtcolor, bg: color }} onClick={viderInputs}>
                <Text fontSize={{ base: 12, lg: 15 }}>Clients
                  {countc > 0 && (
                    <Badge rounded="full" bg="#df2e38" style={{ padding: "2px 7px 2px 6px" }} color="white" ml='2'>
                      {countc}
                    </Badge>
                  )}</Text>
              </Tab>}

            {(user.role === "SUPERADMIN" || user.role === "ADMIN" || user.role === "RESPO" || user.role === "AGENTSUPPORT") &&

              <Tab _selected={{ color: txtcolor, bg: color }} onClick={viderInputs}>
                <Text fontSize={{ base: 12, lg: 15 }}>{t("Partenaires")}
                  {countp > 0 && (
                    <Badge rounded="full" bg="#df2e38" style={{ padding: "2px 7px 2px 6px" }} color="white" ml='2'>
                      {countp}
                    </Badge>
                  )}</Text>
              </Tab>}
          </TabList>
          <TabPanels >

            {(user.role === "SUPERADMIN" || user.role === "ADMIN" || user.role === "RESPO" || user.role === "AGENTSUPPORT") &&

              <TabPanel>
                <Flex
                  align={'center'}
                  justify={'center'}
                >
                  <Stack
                    w={'full'}
                    maxW='full'
                    bg={bg}
                    rounded={'lg'}
                    p={6}
                    my={5}>
                    {/* Search input */}
                    <Flex
                      justifyContent="space-between"
                      alignItems="center"
                      direction={{ base: 'column', lg: 'row' }}
                      mb={{base:2,md:4}}
                    >
                      <Flex mb={3} w={"100%"}>
                        <Select borderRadius="5" size="sm" fontSize={{ base: 13, lg: 16 }} w={{ base: '70%', lg: '50%' }} mr={5} value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)}>
                          <option value="">{t("Toutes les catégories")}</option>
                          <option value="problem_with_partner">{t("Problème avec partenaire")}</option>
                          <option value="technical_support">{t("Support technique")}</option>
                          <option value="account_assistance">{t("Assistance pour le compte")}</option>
                          <option value="feedback_suggestions">{t("Commentaires et suggestions")}</option>
                          <option value="content_issues">{t("Problèmes de contenu")}</option>
                          <option value="general_inquiries">{t("Demandes générales")}</option>
                          {/* <option value="problem_with_client">Problème avec client</option> */}
                        </Select>

                        <Select borderRadius="5" size="sm" fontSize={{ base: 13, lg: 16 }} w={{ base: '30%', lg: '20%' }} value={selectedEtat} onChange={(e) => setSelectedEtat(e.target.value === '' ? null : e.target.value === 'true')}>
                          <option value="">{t("Tout")}</option>
                          <option value="true">{t("Réglé")}</option>
                          <option value="false">{t("En cours")}</option>
                        </Select>

                      </Flex>

                      <InputGroup  size="sm" w={{ base: '100%', lg: "30%" }}>
                        <InputLeftElement
                          pointerEvents='none'
                          children={<SearchIcon color='gray.300' />}
                        />
                        <Input
                          borderRadius="5"
                          placeholder={t("Recherche...")}
                          value={searchTerm}
                          onChange={(e) => {setCurrentPage(0)
                            setSearchTerm(e.target.value)}}
                          mb={4}
                          sx={{
                            marginLeft: 'auto',
                          }}
                        />
                      </InputGroup>

                    </Flex>

                    {/* Plainte Table  */}

                    <TableContainer>
                      <Table size={'sm'} variant='striped' colorScheme="gray">
                        <Thead>
                          <Tr>
                            <Th fontFamily='Plus Jakarta Display'>Code</Th>
                            <Th fontFamily='Plus Jakarta Display' >{t("Client")}</Th>
                            <Th fontFamily='Plus Jakarta Display'>Date</Th>
                            <Th fontFamily='Plus Jakarta Display'>{t("État")}</Th>
                            <Th fontFamily='Plus Jakarta Display'>{t("Catégorie")}</Th>
                            <Th fontFamily='Plus Jakarta Display'>{t("Contenu")}</Th>
                            <Th fontFamily='Plus Jakarta Display'>{t("Réponse")}</Th>
                            <Th fontFamily='Plus Jakarta Display'>{t("Historique")}</Th>

                          </Tr>
                        </Thead>
                        <Tbody>
                          {loadingData &&
                            <Tr>
                              <Td> <Skeleton height='20px' /></Td>
                              <Td> <Skeleton height='20px' /></Td>
                              <Td> <Skeleton height='20px' /></Td>
                              <Td> <Skeleton height='20px' /></Td>
                              <Td> <Skeleton height='20px' /></Td>
                              <Td> <Skeleton height='20px' /></Td>
                              <Td> <Skeleton height='20px' /></Td>
                              <Td> <Skeleton height='20px' /></Td>

                            </Tr>}
                            {(paginationDataCl?.length !== 0) &&
                                dataClients?.map((row) => (
                                <Tr key={row.id}>
                                  <Td>{row.code}</Td>
                                  <Td>{row.first_name} {row.last_name}</Td>

                                  <Td>{new Date(row.created_at).toLocaleDateString(t('fr-FR'))}</Td>
                                  <Td>
                                    <Flex>
                                      <Stack direction='row' mr="3px">
                                        <Switch
                                          colorScheme='green'
                                          isChecked={row.etat}
                                          onChange={() => handleEtatClick(row.id, row.etat)}
                                        />
                                      </Stack>
                                      <Badge
                                        rounded={'lg'}
                                        colorScheme={!row.etat ? 'red' : 'green'}
                                      >
                                        {row.etat ? t('Réglé') : t('En cours')}
                                      </Badge>
                                    </Flex>
                                  </Td>
                                  <Td>{row.category === "technical_support" ? t("Support technique") :
                                    row.category === "account_assistance" ? t("Assistance pour le compte") :
                                      row.category === "feedback_suggestions" ? t("Commentaires et suggestions") :
                                        row.category === "content_issues" ? t("Problèmes de contenu") :
                                          row.category === "general_inquiries" ? t("Demandes générales") :
                                            row.category === "problem_with_partner" ? t("Problème avec partenaire") : ""}</Td>


                                  <Td p={4}>

                                    <Button
                                      size="sm"
                                      onClick={() => {
                                        setDeleteId(row.id);
                                        setSujet(row.sujet);
                                        setContent(row.content);
                                        onOpen();
                                      }}
                                      colorScheme='blue'
                                      leftIcon={<BiDetail />}>
                                      {t("Afficher")}
                                    </Button></Td>

                                  <Td p={4}>
                                    <Button
                                      size="sm"
                                      //onClick={onOpen}

                                      colorScheme='green'
                                      onClick={() => {
                                        setDeleteId(row.id);
                                        setReponse(row.response);
                                        setReponseUser(row.responseUser)
                                        onOpenAlert();
                                        setResUserByWho(row.responseUserbywho)
                                        setUserTime(row.responseUsertime)

                                        setResByWho(row.responsebywho)
                                        setTime(row.responsetime)
                                      }}
                                      leftIcon={<MdOutlineQuestionAnswer />}>
                                      {t("Repondre")}
                                    </Button></Td>

                                  <Td p={4}><Popover placement="left" >
                                    <PopoverTrigger>
                                      <Button
                                        size="sm"
                                        mr="5px"
                                        bg="gray.300"
                                        color={"black"}
                                        leftIcon={<MdHistory />}>
                                        {t("Historique")}
                                      </Button>

                                    </PopoverTrigger>
                                    <PopoverContent w={"full"} color="black" bgGradient='linear(to-r, white, gray)' >
                                      <PopoverHeader letterSpacing={1.5} pt={4} fontWeight="bold" border="0">
                                        {t("Historique")}
                                      </PopoverHeader>
                                      <PopoverArrow bg="gray" />
                                      <PopoverCloseButton />
                                      <PopoverBody>

                                        <div style={{ marginBottom: '8px' }}>
                                          {t("Modifié par")} : {row.edited_by}
                                        </div>

                                        <div>
                                          {t("Date de dernière modification")}: {new Date(row.date_modification).toLocaleString(t('fr-FR'))}
                                        </div>
                                      </PopoverBody>
                                      <PopoverHeader pt={4} fontWeight="bold" border="0">
                                      </PopoverHeader>
                                    </PopoverContent>
                                  </Popover></Td>
                                  {/* <Td>
                                    <Button
                                      size="sm"
                                      //onClick={onOpen}
                                      //onClick={() => handleDelete(row.id)}
                                      onClick={() => {
                                        setDeleteId(row.id);
                                        onOpenAlert();
                                      }}
                                      colorScheme='red'
                                      leftIcon={<AiFillDelete />}>
                                      Supprimer
                                    </Button></Td> */}
                                </Tr>
                          ))}


                          {/* when there is no search data found */}
                          {((searchTerm || selectedEtat!==null || selectedCategory!=="") && paginationDataCl?.length === 0 || (checkLengthData)) && (
                            <Tr>
                              <Td colSpan={10}>{t("Aucune ligne correspondante n'a été trouvée.")}</Td>
                            </Tr>
                          )}
                        </Tbody>
                      </Table>



                    </TableContainer>

                    {/* Pagination */}
                    <Flex flexDirection={{base:'column',lg:"row"}} justify="space-between" align="center" w="100%">
                      <Box flex="1">
                      <MyPagination
                                paginationData={paginationDataCl} 
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                PAGE_SIZE={pageSize}
                            />
                      </Box>

                      <Select w="70px" value={pageSize} onChange={handlePageSizeChange}>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">40</option>
                        <option value="50">50</option>
                        {/* Add more options as needed */}
                      </Select>
                    </Flex>
                  </Stack>
                </Flex>
              </TabPanel>}

            {(user.role === "SUPERADMIN" || user.role === "ADMIN" || user.role === "RESPO" || user.role === "AGENTSUPPORT") &&

              <TabPanel>
                <Flex
                  align={'center'}
                  justify={'center'}
                >
                  <Stack
                    w={'full'}
                    maxW='full'
                    bg={bg}
                    rounded={'lg'}
                    p={6}
                    my={5}>
                    {/* Search input */}
                    <Flex
                      justifyContent="space-between"
                      alignItems="center"
                      direction={{ base: 'column', lg: 'row' }}
                      mb={{base:2,md:4}}
                    >
                      <Flex mb={3} w={"100%"}>
                        <Select borderRadius="5" size="sm" fontSize={{ base: 13, lg: 16 }} w={{ base: '70%', lg: '50%' }} mr={5} value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)}>
                          <option value="">{t("Toutes les catégories")}</option>
                          <option value="problem_with_client">{t("Problème avec client")}</option>
                          <option value="technical_support">{t("Support technique")}</option>
                          <option value="account_assistance">{t("Assistance pour le compte")}</option>
                          <option value="feedback_suggestions">{t("Commentaires et suggestions")}</option>
                          <option value="content_issues">{t("Problèmes de contenu")}</option>
                          <option value="general_inquiries">{t("Demandes générales")}</option>
                        </Select>

                        <Select borderRadius="5" size="sm" fontSize={{ base: 13, lg: 16 }} w={{ base: '30%', lg: '20%' }} value={selectedEtat} onChange={(e) => setSelectedEtat(e.target.value === '' ? null : e.target.value === 'true')}>
                          <option value="">{t("Tout")}</option>
                          <option value="true">{t("Réglé")}</option>
                          <option value="false">{t("En cours")}</option>
                        </Select>

                      </Flex>

                      <InputGroup  size="sm" w={{ base: '100%', lg: "30%" }}>
                        <InputLeftElement
                          pointerEvents='none'
                          children={<SearchIcon color='gray.300' />}
                        />
                        <Input
                          borderRadius="5"
                          placeholder={t("Recherche...")}
                          value={searchTerm}
                          onChange={(e) => {setCurrentPage(0)
                            setSearchTerm(e.target.value)}}
                          mb={4}
                          sx={{
                            marginLeft: 'auto',
                          }}
                        />
                      </InputGroup>

                    </Flex>

                    {/* Plainte Table  */}

                    <TableContainer>
                      <Table size={'sm'} variant='striped' colorScheme="gray">
                        <Thead>
                          <Tr>
                            <Th fontFamily='Plus Jakarta Display'>Code</Th>
                            <Th fontFamily='Plus Jakarta Display' >{t("Partenaire")}</Th>
                            <Th fontFamily='Plus Jakarta Display'>Date</Th>
                            <Th fontFamily='Plus Jakarta Display'>{t("État")}</Th>
                            <Th fontFamily='Plus Jakarta Display'>{t("Catégorie")}</Th>
                            <Th fontFamily='Plus Jakarta Display'>{t("Contenu")}</Th>
                            <Th fontFamily='Plus Jakarta Display'>{t("Réponse")}</Th>
                            <Th fontFamily='Plus Jakarta Display'>{t("Historique")}</Th>

                          </Tr>
                        </Thead>
                        <Tbody>
                          {loadingData &&
                            <Tr>
                              <Td> <Skeleton height='20px' /></Td>
                              <Td> <Skeleton height='20px' /></Td>
                              <Td> <Skeleton height='20px' /></Td>
                              <Td> <Skeleton height='20px' /></Td>
                              <Td> <Skeleton height='20px' /></Td>
                              <Td> <Skeleton height='20px' /></Td>
                              <Td> <Skeleton height='20px' /></Td>
                              <Td> <Skeleton height='20px' /></Td>

                            </Tr>}
                            {(paginationDataPr?.length !== 0) &&
                                dataPartenaires?.map((row) => {
                            {
                              return (
                                <Tr key={row.id}>
                                  <Td>{row.code}</Td>
                                  <Td>{row.company_name} </Td>

                                  <Td>{new Date(row.created_at).toLocaleDateString(t('fr-FR'))}</Td>
                                  <Td>
                                    <Flex>
                                      <Stack direction='row' mr="3px">
                                        <Switch
                                          colorScheme='green'
                                          isChecked={row.etat}
                                          onChange={() => handleEtatClick(row.id, row.etat)}
                                        />
                                      </Stack>
                                      <Badge
                                        rounded={'lg'}
                                        colorScheme={!row.etat ? 'red' : 'green'}
                                      >
                                        {row.etat ? t('Réglé') : t('En cours')}
                                      </Badge>
                                    </Flex>
                                  </Td>
                                  <Td>{row.category === "technical_support" ? t("Support technique") :
                                    row.category === "account_assistance" ? t("Assistance pour le compte") :
                                      row.category === "feedback_suggestions" ? t("Commentaires et suggestions") :
                                        row.category === "content_issues" ? t("Problèmes de contenu") :
                                          row.category === "general_inquiries" ? t("Demandes générales")
                                            : row.category === "problem_with_client" ? t("Problème avec client") : ""}</Td>


                                  <Td p={4}>

                                    <Button
                                      size="sm"
                                      onClick={() => {
                                        setDeleteId(row.id);
                                        setSujet(row.sujet);
                                        setContent(row.content);
                                        onOpen();
                                      }}
                                      colorScheme='blue'
                                      leftIcon={<BiDetail />}>
                                      {t("Afficher")}
                                    </Button></Td>

                                  <Td p={4}>
                                    <Button
                                      size="sm"
                                      //onClick={onOpen}

                                      colorScheme='green'
                                      onClick={() => {
                                        setDeleteId(row.id);
                                        setReponse(row.response);
                                        setReponseUser(row.responseUser)
                                        onOpenAlert();
                                        setResUserByWho(row.responseUserbywho)
                                        setUserTime(row.responseUsertime)

                                        setResByWho(row.responsebywho)
                                        setTime(row.responsetime)
                                      }}
                                      leftIcon={<MdOutlineQuestionAnswer />}>
                                      {t("Repondre")}
                                    </Button></Td>

                                  <Td p={4}><Popover placement="left" >
                                    <PopoverTrigger>
                                      <Button
                                        size="sm"
                                        mr="5px"
                                        bg="gray.300"
                                        color={"black"}
                                        leftIcon={<MdHistory />}>
                                        {t("Historique")}
                                      </Button>

                                    </PopoverTrigger>
                                    <PopoverContent w={"full"} color="black" bgGradient='linear(to-r, white, gray)' >
                                      <PopoverHeader letterSpacing={1.5} pt={4} fontWeight="bold" border="0">
                                        {t("Historique")}
                                      </PopoverHeader>
                                      <PopoverArrow bg="gray" />
                                      <PopoverCloseButton />
                                      <PopoverBody>

                                        <div style={{ marginBottom: '8px' }}>
                                          {t("Modifié par")} : {row.edited_by}
                                        </div>

                                        <div>
                                          {t("Date de dernière modification")}: {new Date(row.date_modification).toLocaleString(t('fr-FR'))}
                                        </div>
                                      </PopoverBody>
                                      <PopoverHeader pt={4} fontWeight="bold" border="0">
                                      </PopoverHeader>
                                    </PopoverContent>
                                  </Popover></Td>
                                  {/* <Td>
                      <Button
                        size="sm"
                        //onClick={onOpen}
                        //onClick={() => handleDelete(row.id)}
                        onClick={() => {
                          setDeleteId(row.id);
                          onOpenAlert();
                        }}
                        colorScheme='red'
                        leftIcon={<AiFillDelete />}>
                        Supprimer
                      </Button></Td> */}
                                </Tr>
                              )
                            }

                          })}

                          {/* when there is no search data found */}
                          {((searchTerm || selectedEtat!==null || selectedCategory!=="") && paginationDataPr?.length === 0 || (checkLengthData)) && (
                            <Tr>
                              <Td colSpan={10}>{t("Aucune ligne correspondante n'a été trouvée.")}</Td>
                            </Tr>
                          )}
                        </Tbody>
                      </Table>



                    </TableContainer>

                    {/* Pagination */}
                    <Flex flexDirection={{base:'column',lg:"row"}} justify="space-between" align="center" w="100%">
                      <Box flex="1">
                      <MyPagination
                                paginationData={paginationDataPr} 
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                PAGE_SIZE={pageSize}
                            />
                      </Box>

                      <Select w="70px" value={pageSize} onChange={handlePageSizeChange}>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">40</option>
                        <option value="50">50</option>
                        {/* Add more options as needed */}
                      </Select>
                    </Flex>
                  </Stack>
                </Flex>
              </TabPanel>}
          </TabPanels>
        </Tabs>
      </Stack>

      <Modal
        closeOnOverlayClick={plainteData.response ? false : true}
        isOpen={isAlertOpen}
        onClose={() => {
          setMessage('')
          setPlainteData({})
          onCloseAlert()
        }}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t("Réponse")}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            {/* <FormControl>
              <Textarea
                maxLength={250}
                borderColor="gray.300"
                defaultValue={reponse}
                _hover={{
                  borderRadius: 'gray.300',
                }}
                h="100px"
                name="response"
                onChange={handleInputChange}
                placeholder="Ecrivez votre réponse"
              />
            </FormControl> */}
            {message &&
              <Alert fontSize={"sm"} padding={2} mb={2} status='error' rounded="md">
                <AlertIcon />
                {message}
              </Alert>
            }

            <FormControl>
              {

                <>
                  {formatDateToCustomFormat(time) > formatDateToCustomFormat(userTime) ?
                    <>
                      {/* {reponseUser ?
                        <Flex direction={"column"} >

                          <Text ml={2} fontSize={"xs"}>{resUserByWho} </Text>
                          <Flex bg="gray.100" color="black" rounded="2xl" padding={2} w="50%" justifyContent="start">{reponseUser}</Flex>
                          <Text w={"50%"} align={"end"} fontSize={10}>{formatDateToCustomFormat(userTime)}</Text>
                        </Flex>

                        : null} */}
                      {reponse ? <Flex w="100%" justifyContent="end" alignItems="right">

                        <Flex direction={"column"} justifyContent="end" w={"100%"} >
                          <Text ml={2} fontSize={"xs"}>{resByWho} </Text>
                          <Flex bg="green.100" color="black" rounded="2xl" padding={2} justifyContent="end">
                            <Text w="100%">{reponse}</Text>
                          </Flex>
                          <Text align={"end"} fontSize={10}>{formatDateToCustomFormat(time)}</Text>

                        </Flex>
                      </Flex> : null}
                    </> : <>

                      {reponse ? <Flex w="100%" justifyContent="end" alignItems="right">

                        <Flex direction={"column"} justifyContent="end" w={"50%"} >
                          <Text ml={2} fontSize={"xs"}>{resByWho} </Text>
                          <Flex bg="green.100" color="black" rounded="2xl" padding={2} justifyContent="end">
                            <Text w="100%">{reponse}</Text>
                          </Flex>
                          <Text align={"end"} fontSize={10}>{formatDateToCustomFormat(time)}</Text>

                        </Flex>
                      </Flex> : null}
                      {reponseUser ?
                        <Flex direction={"column"} >

                          <Text ml={2} fontSize={"xs"}>{resUserByWho} </Text>
                          <Flex bg="gray.100" color="black" rounded="2xl" padding={2} w="50%" justifyContent="start">{reponseUser}</Flex>
                          <Text w={"50%"} align={"end"} fontSize={10}>{formatDateToCustomFormat(userTime)}</Text>
                        </Flex>

                        : null}
                    </>}


                </>
              }
              <Input mt={5} maxLength={250}
                borderColor="gray.300"
                _hover={{
                  borderRadius: 'gray.300',
                }}
                onChange={handleInputChange}
                name="reponse"
                placeholder={t("Ecrivez votre réponse")}></Input>

            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button onClick={sendResponse} colorScheme='blue' isDisabled={!plainteData.response}>
              {t("Envoyer")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {/* <AlertDialog
        isOpen={isAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={onCloseAlert}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Supprimer le message
            </AlertDialogHeader>

            <AlertDialogBody>
              êtes-vous sûr ? Vous ne pourrez pas annuler cette action ultérieurement.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onCloseAlert}>
                Annuler
              </Button>
              <Button onClick={() => handleDelete(deleteId)} colorScheme='red' ml={3}>
                Supprimer
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog> */}
      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent >
          <ModalHeader>{sujet}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {content}
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>{t("Fermer")}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>




      <Modal size="5xl" isOpen={isImgOpen} onClose={onCloseImg}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <Image
              objectFit='cover'
              boxSize={"100%"}
              src={img}
            />
          </ModalBody>


        </ModalContent>
      </Modal>

    </Box>
  )
}


