import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Box, Button, Card, CardBody, CardHeader, Flex, Grid, GridItem, Icon, IconButton, Image, Input, InputGroup, InputLeftAddon, InputLeftElement, Menu, MenuButton, MenuItem, MenuList, Select, SimpleGrid, Skeleton, Text, useColorModeValue, useToast } from '@chakra-ui/react'
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axiosClient from '../../axios-client';
import { DeleteIcon, EditIcon, HamburgerIcon, SearchIcon } from '@chakra-ui/icons';
import { RangeDatepicker } from 'chakra-dayzed-datepicker';
import { useTranslation } from 'react-i18next';
import MyPagination from '../../components/MyPagination';
import notfound from '../../assets/images/notfound.png';

function AllEvents() {
    const { t } = useTranslation();

    const [data, setData] = useState([]);
    const cancelRef = React.useRef()
    const [message, setMessage] = useState('');

    const [loadingData, setLoadingData] = useState(true)
    const [checkLengthData, setCheckLengthData] = useState(false)
    const [paginationData, setPaginationData] = useState()

    const [selectedEtat, setSelectedEtat] = useState(null);

    const [searchTerm, setSearchTerm] = useState('');

    const navigate = useNavigate()
    const textColor = useColorModeValue("gray.400", "gray.200");
    const [currentPage, setCurrentPage] = useState(0);

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    //toast variable
    const toast = useToast()

    const [showGradient, setShowGradient] = useState(-1)
    const [deleteId, setDeleteId] = useState(null)

    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const onCloseAlert = () => {
        setIsAlertOpen(false);
    };
    ////////////////////////////////the size of the table//////////////////////////////
    const PAGE_SIZE = 12;
    const [pageSize, setPageSize] = useState(PAGE_SIZE);
    const handlePageSizeChange = (event) => {
        const newSize = parseInt(event.target.value, 10);
        setPageSize(newSize);
        setCurrentPage(0);
    };
    //////////////////////////////////////////////////////////////////////////////////

    const onOpenAlert = () => {
        setIsAlertOpen(true);
    };
    const [loading, setLoading] = useState(true);

    useEffect(() => {

        axiosClient.get(`/events/getAllEvents/?page_size=${pageSize}&page=${currentPage + 1}&search_term=${searchTerm}&start_date=${startDate ? startDate : ""}&end_date=${endDate ? endDate : ""}&etat=${selectedEtat}`)
            .then((response) => {
                let filteredData = response.data.results;
                setPaginationData(response.data);

                if (filteredData.length === 0) {
                    setCheckLengthData(true)
                }
                else {
                    setData(filteredData);
                }
                setLoadingData(false)
            })
            .catch((error) => {
                console.error(error);
                setLoadingData(false)

            });

    }, [pageSize, currentPage, searchTerm, startDate, endDate, selectedEtat])
    // const filteredData = useCallback(() => {
    //     return data.filter((row) => {
    //         // Check if the start and end dates match the search term
    //         const isStartDateMatch = !startDate || (row.start_day.includes(startDate) || startDate <= row.start_day);
    //         const isEndDateMatch = !endDate || (row.end_day.includes(endDate) || endDate >= row.end_day);

    //         // Check if the search term matches any of the event fields
    //         const isSearchTermMatch = (
    //             row.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //             row.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //             row.localisation.toLowerCase().includes(searchTerm.toLowerCase())
    //         );

    //         // Apply date filtering, search term filtering, or both
    //         if (startDate || endDate) {
    //             return (isSearchTermMatch && isStartDateMatch && isEndDateMatch);
    //         } else {
    //             return isSearchTermMatch;
    //         }
    //     });
    // }, [data, searchTerm, startDate, endDate]);


    // const filteredData = useCallback(() => {
    //     return data.filter((row) => {
    //         const isSearchTermMatch =
    //             // Check if the search term matches any of the event fields
    //             row.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //             row.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //             row.localisation.toLowerCase().includes(searchTerm.toLowerCase());

    //         // Check if the event's start and end dates fall within the selected range
    //         const isDateRangeMatch =
    //             (!startDate || new Date(row.start_day) >= startDate) &&
    //             (!endDate || new Date(row.end_day) <= endDate);

    //         return isSearchTermMatch && isDateRangeMatch;
    //     });
    // }, [data, searchTerm, startDate, endDate]);

    const handleDeleteEvent = (id) => {
        if (!id) return;
        axiosClient.delete(`/events/${id}/`)
            .then((response) => {
                setData((prevData) => prevData.filter((row) => row.id !== id));
                toast({
                    description: t("L'événement est supprimé avec succès"),
                    status: 'success',
                    variant: 'left-accent',
                    duration: 2000,
                    isClosable: true,
                    position: 'bottom-right'
                })
                onCloseAlert()
            })
            .catch((error) => {
                console.error(error);
            });
    }

    return (
        <Box mt="5px" >
            <Flex>
                <Box w="90%" mt={2} mb={5}>
                    <Text fontFamily='Plus Jakarta Display' as="cite" fontSize={{ base: "18px", md: "20px", lg: "25px" }} color="gray"><span onClick={() => navigate('/')} style={{ cursor: 'pointer' }} onMouseEnter={e => e.target.style.textDecoration = "underline"} onMouseLeave={e => e.target.style.textDecoration = "none"} >{t("Accueil")}</span>  / <span onClick={() => navigate('/evenements')} style={{ cursor: 'pointer' }} onMouseEnter={e => e.target.style.textDecoration = "underline"} onMouseLeave={e => e.target.style.textDecoration = "none"} > {t("Espace Événements")} </span></Text>
                </Box>
            </Flex>

            <Card bg={'none'} shadow={'none'} p={{ base: "5px", md: '16px' }} >
                <CardHeader p='1px 5px' mb='10px'>
                    <Flex gap={5} w={"100%"} flexDirection={{ base: 'column', lg: 'column' }} >

                        <Text mb={{ base: 3, lg: 0 }} fontSize={{ base: 'md', sm: 'lg', md: "xl" }} color='gray.500' fontWeight='Bold' >
                            {t("Consultez la liste des événements !!")}
                        </Text>
                        {/* Search input */}
                        <Flex
                            gap={{ base: 3, lg: 3 }}
                            direction={{ base: 'column', xl: 'row' }}
                            justifyContent={"space-between"}
                            w={"100%"}
                        >


                            <Flex gap={2} w={{ base: "100%", xl: "60%" }} direction={{ base: 'column', lg: 'row' }}>
                                <Select w={{ base: "100%", lg: "30%" }} borderColor={'gray.300'} borderRadius="5" fontSize={{ base: 13, lg: 16 }} value={selectedEtat} onChange={(e) => setSelectedEtat(e.target.value === '' ? null : e.target.value === 'true')}>
                                    <option value="">{t("Tout")}</option>
                                    <option value="true">{t("Activé")}</option>
                                    <option value="false">{t("Désactivé")}</option>
                                </Select>
                                <Flex flexDirection={{ base: "column", md: "row" }} w={{ base: "100%", lg: "70%" }} gap={2}>
                                    <InputGroup size={"md"} >
                                        <InputLeftAddon children={t('De :')} />
                                        <Input
                                            type="date"
                                            borderColor={'gray.300'}
                                            borderRadius={"5"}
                                            placeholder="Start Date"
                                            // value={startDate}
                                            onChange={(e) => setStartDate(e.target.value)}
                                        />
                                    </InputGroup>

                                    <InputGroup size={"md"} >
                                        <InputLeftAddon children={t('À :')} />

                                        <Input
                                            type="date"
                                            borderColor={'gray.300'}
                                            borderRadius={"5"}
                                            placeholder="End Date"
                                            // value={endDate}
                                            onChange={(e) => setEndDate(e.target.value)}
                                        />
                                    </InputGroup>
                                </Flex>


                            </Flex>

                            <InputGroup size={"md"} w={{ base: '100%', xl: "30%" }}>
                                <InputLeftElement
                                    pointerEvents='none'
                                    children={<SearchIcon color='gray.300' />}
                                />
                                <Input
                                    borderColor={'gray.300'}
                                    borderRadius={"5"}
                                    placeholder={t("Recherche...")}
                                    value={searchTerm}
                                    sx={{
                                        marginLeft: 'auto',
                                    }}
                                    onChange={(e) => {
                                        setCurrentPage(0)
                                        setSearchTerm(e.target.value)
                                    }}
                                />  </InputGroup>



                        </Flex>

                    </Flex>
                </CardHeader>
                {loadingData &&
                    <CardBody >
                        <Grid templateColumns={{ sm: "1fr", md: "1fr 1fr", xl: "repeat(3, 1fr)" }}
                            templateRows={{ sm: "1fr 1fr 1fr auto", md: "1fr 1fr", xl: "1fr" }} gap={6}>
                            <Skeleton><GridItem h='50' /></Skeleton>
                            <Skeleton><GridItem h='50' /></Skeleton>
                            <Skeleton><GridItem h='50' /></Skeleton>

                        </Grid>
                    </CardBody>}
                <CardBody>

                    {(paginationData?.length !== 0) &&
                        <SimpleGrid

                            spacing={7} templateColumns='repeat(auto-fill, minmax(250px, 1fr))'>
                            {

                                data.map((val, key) => (
                                    <Flex direction='column' h={"100%"} >


                                        <Box display={'flex'} justifyContent={'center'} height={'fit-content'} position='relative' borderRadius='15px' cursor="pointer" onMouseEnter={() => setShowGradient(key)} onMouseLeave={() => setShowGradient(-1)}>
                                            {showGradient === key &&
                                                <Box
                                                    position='absolute'
                                                    top='10px'
                                                    right='10px'
                                                    cursor='pointer'
                                                >
                                                    <Menu >
                                                        <MenuButton
                                                            as={IconButton}
                                                            aria-label='Options'
                                                            icon={<HamburgerIcon />}
                                                            borderRadius={"full"}
                                                            background={'rgb(189,190,191,0.6)'}
                                                            // opacity={.8}
                                                            _hover={'none'}
                                                            variant='outline'
                                                        />
                                                        <MenuList size={"sm"}>


                                                            <MenuItem onClick={() => navigate(`/modifier-evenement`, {
                                                                state: {
                                                                    idEvent: val.id
                                                                }
                                                            })}
                                                                icon={<EditIcon />} >
                                                                {t('Modifier')}
                                                            </MenuItem>
                                                            <MenuItem onClick={(e) => {
                                                                setDeleteId(val.id)
                                                                onOpenAlert()
                                                            }} icon={<DeleteIcon />} >
                                                                {t("Supprimer")}
                                                            </MenuItem>
                                                        </MenuList>
                                                    </Menu>
                                                </Box>}

                                            <Image w={"100%"} minH={"400px"} maxH={"400px"} h={"100%"} objectFit={'cover'} src={val.image} borderRadius='15px' />

                                            {showGradient !== key &&
                                                <Box

                                                    w='100%'
                                                    h='100%'
                                                    position='absolute'
                                                    top='0'
                                                    borderRadius='15px'
                                                    bg='linear-gradient(180deg, rgba(49, 56, 96, 0.16) 0%, rgba(21, 25, 40, 0.88) 70%)'
                                                >
                                                    <Flex direction='column' align={'center'} justify={"end"} h="100%">
                                                        <Text px={3} fontSize='xl' color='gray.100' fontWeight='600' mb='10px'>
                                                            {val.title}
                                                        </Text>
                                                        <Text px={3} fontSize='lg' color={textColor} fontWeight='bold' mb='10px'>
                                                            {val.description.substring(0, 50)}
                                                        </Text>
                                                        <Text px={3} fontSize='md' color='gray.100' fontWeight='400' mb={'10px'} >
                                                            {val.localisation}
                                                        </Text>
                                                        <Flex px={5} mb='20px' w="100%" fontSize='md' justifyContent={"space-between"} color='gray.100' >
                                                            <Text fontSize={"sm"}> {t('De :')} {new Date(val.start_day).toLocaleDateString(t('fr-FR'))}</Text>
                                                            <Text fontSize={"sm"}> {t("À :")} {new Date(val.end_day).toLocaleDateString(t('fr-FR'))}</Text>
                                                        </Flex>
                                                    </Flex>
                                                </Box>}
                                        </Box>


                                    </Flex>

                                ))}
                        </SimpleGrid>}


                </CardBody>
            </Card>
            {(((searchTerm || selectedEtat!==null ||  startDate!==null || endDate!==null) && paginationData?.length === 0) || (checkLengthData)) && (
                <Flex direction={"column"} p={{ base: 5, md: 10, lg: 20 }} w={"100%"} justify={"center"} alignItems={'center'}>
                    <Text color={'gray.500'} fontWeight={"semibold"}>{t("Aucun événement correspondant n'a été trouvé.")}</Text>

                    <Image opacity={0.5} src={notfound}></Image>
                </Flex>

            )}
            {/* Pagination */}
            <Flex flexDirection={{ base: 'column', lg: "row" }} justify="space-between" align="center" w="100%">
                <Box flex="1">
                    <MyPagination
                        paginationData={paginationData}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        PAGE_SIZE={pageSize}
                    />
                </Box>

                <Select w="70px" value={pageSize} onChange={handlePageSizeChange}>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="40">40</option>
                    <option value="50">50</option>
                    {/* Add more options as needed */}
                </Select>
            </Flex>
            <AlertDialog
                closeOnOverlayClick={false}
                isOpen={isAlertOpen}
                leastDestructiveRef={cancelRef}
                onClose={onCloseAlert}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            {t("Supprimer")}
                        </AlertDialogHeader>

                        <AlertDialogBody>


                            <Text>
                                {t("êtes-vous sûr ? Vous ne pourrez pas annuler cette action ultérieurement.")}
                            </Text>

                        </AlertDialogBody>



                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={() => {
                                setMessage(false)
                                onCloseAlert()
                            }}>
                                {t("Annuler")}
                            </Button>
                            <Button onClick={() => handleDeleteEvent(deleteId)} colorScheme='red' ml={3} isDisabled={message}>
                                {t("Supprimer")}
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </Box>
    )
}

export default AllEvents