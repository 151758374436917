import { Navigate, createBrowserRouter } from "react-router-dom";
import DefaultLayout from "./components/DefaultLayout";
import GuestLayout from "./components/GuestLayout";
import RequireAuth from "./hooks/RequireAuth";

// import Dashboard from "./views/dashboard/Dashboard";

import Login from "./views/auth/Login";
import Dashboard from "./views/dashboard/Dashboard";
import GestAdmins from "./views/gestion_administrateurs/GestAdmins";
import { GestClients } from "./views/gestion_clients/GestClients";
import GestPartenaires from "./views/gestion_partenaires/GestPartenaires";
import Profile from "./views/profile/Profil";
import EditProfile from "./views/profile/EditProfile";
import About from "./views/About";
import AddAdmin from "./views/gestion_administrateurs/AddAdmin";
import Entreprise from "./views/gestion_partenaires/Entreprise";
import RegisterDemande from "./views/gestion_demandes_inscription/RegisterDemande";
import { GestTickets } from "./views/gestion_tickets/GestTickets";
import GestCategory from "./views/gestion_categories/GestCategory";
import GestLocations from "./views/gestion_locations/GestLocations";
import TypeAbonnements from "./views/gestion_abonnements/TypeAbonnements";
import GestionContact from "./views/gestion_contact/GestionContact";
import EditAdmin from "./views/gestion_administrateurs/EditAdmin";
import GestEvents from "./views/gestion_evenements/GestEvents";
import AddEvent from "./views/gestion_evenements/AddEvent";
import AllEvents from "./views/gestion_evenements/AllEvents";
import EditEvent from "./views/gestion_evenements/EditEvent";
import ResetPassword from "./views/auth/ResetPassword";
import ResetPwdPage from "./views/auth/ResetPwdPage";
import GestNotifications from "./views/gestion_notifications/GestNotifications";
import Security from "./views/profile/Security";
import AddPartenaire from "./views/gestion_partenaires/AddPartenaire";
import EditPartenaire from "./views/gestion_partenaires/EditPartenaire";
import GestServices from "./views/gestion_services/GestServices";
import AddService from "./views/gestion_services/AddService";
import EditService from "./views/gestion_services/EditService";
import { useEffect, useState } from "react";
import GestPromotions from "./views/gestion_promotions/GestPromotions";
import GestPartenairesSignales from "./views/gestion_partenaires_signalés/GestPartenairesSignales";
import Unauthorized from "./views/Unauthorized";
import NotFound from "./views/NotFound";
import ContactUs from "./views/ContactUs";
import Rooms from "./views/chat_room/Rooms";
import AllRooms from "./views/chat_room/AllRooms";


const MainComponent = () => {
  const [userRole, setUserRole] = useState(localStorage.getItem('role') || 'ADMIN');

  useEffect(() => {
    setUserRole(localStorage.getItem('role') || 'ADMIN');
  }, [localStorage.getItem('role')]);

  return (
    <RequireAuth
      element={
        userRole === 'RESPOEVENTS' ? <GestEvents /> : 
        userRole === 'AGENTSUPPORT' ? <GestTickets /> : 
        <Dashboard />
      }
      allowedRoles={['SUPERADMIN', 'ADMIN', 'MASTER', 'RESPO', 'RESPOEVENTS', 'AGENTSUPPORT']}
    />
  );
};
const router = createBrowserRouter([
  {
    path: '/',
    element: <DefaultLayout />,
    children: [
      {
        path: '/',
        element: <MainComponent />
      },
    
      // {
      //   path: '/',
      //   element:<RequireAuth element={<Dashboard/>}/>  
      // },
      {
        path: '/admins',
        element:<RequireAuth element={<GestAdmins/>}/>  
      },
      {
        path: '/clients',
        element:<RequireAuth element={<GestClients/>}/>  
      },
      {
        path: '/partenaires',
        element:<RequireAuth element={<GestPartenaires/>}/>  
      },
      {
        path: '/ajouter-partenaire',
        element:<RequireAuth element={<AddPartenaire/>}/>  
      },
      {
        path: '/entreprise',
        element:<RequireAuth element={<Entreprise/>}/>  
      },
      {
        path: '/profil',
        element:<RequireAuth element={<Profile/>}/>  
      },
      {
        path: '/modifier-profil',
        element:<RequireAuth element={<EditProfile/>}/>  
      },
      {
        path: '/ajouter-admin',
        element:<RequireAuth element={<AddAdmin/>}/>  
      },
      {
        path: '/modifier-admin',
        element:<RequireAuth element={<EditAdmin/>}/>  
      },
      {
        path: '/demandes-inscription',
        element:<RequireAuth element={<RegisterDemande/>}/>  
      },
      {
        path: '/tickets',
        element:<RequireAuth element={<GestTickets/>}/>  
      },
      {
        path: '/categories',
        element:<RequireAuth element={<GestCategory/>}/>  
      },
      {
        path: '/localisations',
        element:<RequireAuth element={<GestLocations/>}/>  
      },
      {
        path: '/aPropos',
        element:<RequireAuth element={<About/>}/>  
      },
      {
        path: '/types_abonnements',
        element:<RequireAuth element={<TypeAbonnements/>}/>  
      },
      {
        path: '/contacts',
        element:<RequireAuth element={<GestionContact/>}/>  
      },
      {
        path: '/evenements',
        element:<RequireAuth element={<GestEvents/>}/>  
      },
      {
        path: '/ajouter-evenement',
        element:<RequireAuth element={<AddEvent/>}/>  
      },
      {
        path: '/liste-evenements',
        element:<RequireAuth element={<AllEvents/>}/>  
      },
      {
        path: '/modifier-evenement',
        element:<RequireAuth element={<EditEvent/>}/>  
      },
      {
        path: '/notifications',
        element:<RequireAuth element={<GestNotifications/>}/>  
      },
      {
        path: '/securite-de-compte',
        element:<RequireAuth element={<Security/>}/>  
      },
      {
        path: '/modifier-partenaire',
        element:<RequireAuth element={<EditPartenaire/>}/>  
      },
      {
        path: '/apa-agency-services',
        element:<RequireAuth element={<GestServices/>}/>  
      },
      {
        path: 'ajouter-service',
        element:<RequireAuth element={<AddService/>}/>  
      },
      {
        path: 'modifier-service',
        element:<RequireAuth element={<EditService/>}/>  
      },
      {
        path: 'apa-promotions',
        element:<RequireAuth element={<GestPromotions/>}/>  
      },
      {
        path: 'partenaires-signales',
        element:<RequireAuth element={<GestPartenairesSignales/>}/>  
      },
      // {
      //   path: 'partenaires-clients-chat',
      //   element:<RequireAuth element={<Chat/>}/>  
      // },
      {
        path: 'chat',
        element:<RequireAuth element={<Rooms/>}/>  
      },
      {
        path: 'rooms',
        element:<RequireAuth element={<AllRooms/>}/>  
      },
     
    ]
  },
  {
    path: '/',
    element: <GuestLayout />,
    children: [
      {
        path: '/login',
        element: <Login />
      },
      {
        path: "/unauthorized",
        element: <Unauthorized />
      },
      {
        path: '/reset_password',
        element: <ResetPassword />
      },
      {
        path: '/reinitialisation_de_motdepasse/:userId/:token/:expires',
        element: <ResetPwdPage />
      },
      {
        path: '/Contactez-nous',
        element: <ContactUs />
      },
    ]
  },

  {
    path: "*",
    element: <NotFound />
  }
])

export default router;
