import { Box, Flex, Text, Button, useColorModeValue, Heading } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';

export default function NotFound() {
    const navigate = useNavigate();
    const bg = useColorModeValue("gray.200", "gray.800");
	const { t } = useTranslation();

    return (
        <Box bg={bg} h="100vh">
            <Flex
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                h="100%"
                textAlign="center"
            >
                 <Text
                display="inline-block"
                
                fontSize="5xl"
                fontWeight="bold"
                bgGradient="linear(to-r, blue.400, blue.600)"
                backgroundClip="text">
                404
            </Text>
            <Text fontSize="3xl" mt={3} mb={2}>
                Page Not Found
            </Text>
            <Text fontSize="xl" color={'gray.500'} mb={6}>
                {t("La page que vous recherchez n'existe pas")}
            </Text>
                <Button
                     colorScheme="blue"
                     bgGradient="linear(to-r, blue.400, blue.500, blue.600)"
                     color="white"
                     variant="solid"
                    onClick={() => navigate(-2)}>
                    {t("Retourner")}
                </Button>

            </Flex>
        </Box>
        
    )
}