import { useState, useEffect, useCallback } from 'react';
import axiosClient from '../../axios-client';
import { useStateContext } from '../../context/ContextProvider';
import MyPagination from "../../components/MyPagination";

import {
    Flex,
    Box,
    Button,
    Stack,
    Input,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    useColorModeValue,
    useToast,
    Spinner,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    InputRightElement,
    Icon,
    InputGroup,
    Text,
    Badge,
    Textarea,
    Select,
    Avatar,
    SkeletonCircle,
    Skeleton,
    InputLeftElement
} from '@chakra-ui/react';
import { FaSearch } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SearchIcon } from '@chakra-ui/icons';

export default function RegisterDemande() {

    const { t } = useTranslation();
    const [loadingData, setLoadingData] = useState(true)
    const [checkLengthData, setCheckLengthData] = useState(false)
    const [paginationData, setPaginationData] = useState()

    // State variables
    const toast = useToast()
    const [data, setData] = useState([]);
    const { user, isLoading, setIsLoading } = useStateContext()
    const [id, setId] = useState(null);
    const [selectedEtat, setSelectedEtat] = useState("");
    const navigate = useNavigate()

    const [currentPage, setCurrentPage] = useState(0);
    //search variable
    const [searchTerm, setSearchTerm] = useState('')
    // Approuver demande Modal Overlay
    const OverlayApprouver = () => (
        <ModalOverlay
            bg='none'
            backdropFilter='auto'
            backdropInvert='80%'
            backdropBlur='2px'
        />
    )

    // Refuser demande Modal Overlay
    const OverlayRefuser = () => (
        <ModalOverlay
            bg='none'
            backdropFilter='auto'
            backdropInvert='80%'
            backdropBlur='2px'
        />
    )

    // Refuser une demande d'inscription
    let [messageRefus, setMessageRefus] = useState('')

    let handleInputChange = (e) => {
        let inputValue = e.target.value
        setMessageRefus(inputValue)
    }

    const handleRefuser = (id) => {
        setIsLoading(true)
        axiosClient.get(`/refuse_demande/${id}/${user.id}`, {
            params: {
                justification: messageRefus
            }
        })
            .then(() => {
                toast({
                    description: "La demande a été refusée",
                    status: 'warning',
                    variant: "left-accent",
                    duration: 3000,
                    isClosable: true,
                    position: 'bottom-right'
                })
                setIsLoading(false);
                onCloseModalB()
                fetchDemandes();
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
            });

    }

    // Approuver une demande d'inscription
    const handleApprouver = (id) => {
        setIsLoading(true);
        axiosClient.get(`/approve_demande/${id}/${user.id}`)
            .then((response) => {
                if (response.data.message) {
                    toast({
                        description: response.data.message,
                        status: 'success',
                        duration: 3000,
                        isClosable: true,
                        variant: "left-accent",
                        position: 'bottom-right'
                    })
                }
                if (response.data.error) {
                    toast({
                        description: response.data.error,
                        status: 'error',
                        duration: 3000,
                        isClosable: true,
                        variant: "left-accent",
                        position: 'bottom-right'
                    })
                }

                setIsLoading(false);
                onCloseModalA()
                fetchDemandes();
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);


            });

    }

    // afficher la liste des demandes
    function fetchDemandes() {
        setIsLoading(true);
        // setLoadingData(true)
        axiosClient.get(`/demandeInscription/getAllRequests/?page_size=${pageSize}&page=${currentPage + 1}&search_term=${searchTerm}&etat=${selectedEtat}`)
            .then((response) => {
                let filteredData = response.data.results;
                setPaginationData(response.data);
                // if (selectedEtat !== null) {
                //     filteredData = filteredData.filter((item) => item.etat === selectedEtat);
                // }
                if (filteredData.length === 0) {
                    setCheckLengthData(true)
                }
                else {
                    setData(filteredData);
                }
                setLoadingData(false)
                setIsLoading(false)
            })

            .catch((error) => {
                console.log(error);
                setLoadingData(false)
                setIsLoading(false);
            });

    }



    // Récuperer l'ID de la demande
    const handleModalEtat = async (id) => {
        setId(id);
    };



    // Modals variables and methods
    const [isModalAOpen, setIsModalAOpen] = useState(false);
    const [isModalBOpen, setIsModalBOpen] = useState(false);

    ////////////////////////////////the size of the table//////////////////////////////
    const PAGE_SIZE = 10;
    const [pageSize, setPageSize] = useState(PAGE_SIZE);
    const handlePageSizeChange = (event) => {
        const newSize = parseInt(event.target.value, 10);
        setPageSize(newSize);
        setCurrentPage(1);
    };
    //////////////////////////////////////////////////////////////////////////////////

    useEffect(() => {
        setIsLoading(true);
        // setLoadingData(true)
        axiosClient.get(`/demandeInscription/getAllRequests/?page_size=${pageSize}&page=${currentPage + 1}&search_term=${searchTerm}&etat=${selectedEtat}`)
            .then((response) => {
                let filteredData = response.data.results;
                setPaginationData(response.data);
                // if (selectedEtat !== null) {
                //     filteredData = filteredData.filter((item) => item.etat === selectedEtat);
                // }
                if (filteredData.length === 0) {
                    setCheckLengthData(true)
                }
                else {
                    setData(filteredData);
                }
                setLoadingData(false)
                setIsLoading(false)
            })

            .catch((error) => {
                console.log(error);
                setLoadingData(false)
                setIsLoading(false);
            });
    }, [selectedEtat, pageSize, currentPage, searchTerm]);

    const onOpenModalA = () => {
        setIsModalAOpen(true);
    };

    const onOpenModalB = () => {
        setIsModalBOpen(true);
    };

    const onCloseModalA = () => {
        setIsModalAOpen(false);
    };

    const onCloseModalB = () => {
        setIsModalBOpen(false);
    };

    const [overlayRefuser, setOverlayRefuser] = useState(<OverlayRefuser />)
    const [overlay, setOverlay] = useState(<OverlayApprouver />)


    //search method
    // const filteredData = useCallback(() => {
    //     return data.filter(row => {
    //         return (
    //             row.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //             row.company_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //             row.email.toLowerCase().includes(searchTerm.toLowerCase())
    //         );
    //     });
    // }, [data, searchTerm, selectedEtat]);


    // format date
    function format(d) {
        const formattedDate = new Date(d).toLocaleDateString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });

        return formattedDate
    }

    // function myFilter() {
    //     const number = 0
    //     if (parseInt(selectedEtat) === 1) {
    //         const myData = filteredData().filter(row => (row.etat === false && row.isApproved === true))
    //         return (myData)
    //     }
    //     if (parseInt(selectedEtat) === 2) {
    //         const myData = filteredData().filter(row => (row.etat === false && row.isApproved === false))
    //         return (myData)
    //     }
    //     if (parseInt(selectedEtat) === 3) {
    //         const myData = filteredData().filter(row => (row.etat === true && row.isApproved === false))
    //         return (myData)
    //     }
    //     return filteredData()
    // }

    // console.log(myFilter());

    return (
        <Box mt="5px" >
            <Flex>
                <Box w="90%" mt={2} mb={5} >
                    <Text fontFamily='Plus Jakarta Display' as="cite" fontSize={{ base: "18px", md: "20px", lg: "25px" }} color="gray"><span onClick={() => navigate('/')} style={{ cursor: 'pointer' }} onMouseEnter={e => e.target.style.textDecoration = "underline"} onMouseLeave={e => e.target.style.textDecoration = "none"} >{t("Accueil")}</span>  / <span >Demandes inscription</span></Text>
                </Box>
            </Flex>
            <Flex
                align={'center'}
                justify={'center'}
            >
                <Stack
                    w={'full'}
                    maxW='full'
                    bg={useColorModeValue('white', 'gray.700')}
                    rounded={'lg'}
                    p={6}
                    my={5}>
                    {/* Start Search input */}
                    <Flex justifyContent="space-between"
                        alignItems="center"
                        gap={2}>
                        <Select borderRadius="5" size="sm" fontSize={{ base: 13, lg: 16 }} w={{ base: '30%', lg: "15%" }} mb={4} value={selectedEtat} onChange={(e) => setSelectedEtat(e.target.value)}>
                            <option value="">{t("Tout")}</option>
                            <option value="Approuvée">{t("Approuvé")}</option>
                            <option value="NonApprouvée">{t("Non Approuvé")}</option>
                            <option value="EnCours">{t("En cours")}</option>
                        </Select>
                        
                        <InputGroup size="sm" w={{ base: '70%', lg: "30%" }} >
                        <InputLeftElement
                                pointerEvents='none'
                                children={<SearchIcon color='gray.300' />}
                            />
                            <Input
                                borderRadius="5"
                                placeholder={t("Recherche...")}
                                value={searchTerm}
                                onChange={(e) => {
                                    setCurrentPage(0)
                                    setSearchTerm(e.target.value)
                                }}
                                mb={4}
                                mr="auto"
                                sx={{
                                    marginRight: "auto",
                                }}
                            />
                        </InputGroup>
                    </Flex>
                    {/* End Search input */}
                    <TableContainer>
                        <Table size="sm" variant='striped' colorScheme="gray">
                            <Thead>
                                <Tr>
                                    <Th fontFamily='Plus Jakarta Display' fontSize="12">Image</Th>

                                    <Th fontFamily='Plus Jakarta Display'>{t('Nom entreprise')}</Th>

                                    <Th fontFamily='Plus Jakarta Display'>Email</Th>
                                    <Th fontFamily='Plus Jakarta Display'>{t('État')}</Th>
                                    {selectedEtat !== "Approuvée" ? <Th fontFamily='Plus Jakarta Display'>{t("Par")}</Th> : null}
                                    <Th fontFamily='Plus Jakarta Display'>{t("Dérnière modification")}</Th>
                                    <Th fontFamily='Plus Jakarta Display'>Options</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {loadingData &&
                                    <Tr>
                                        <Td><SkeletonCircle size='10' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>

                                    </Tr>}
                                {(paginationData?.length !== 0) &&
                                    data.map((demande) => (
                                        <Tr key={demande.id}>
                                            <Td > <Avatar size='md' src={demande.image} /></Td>

                                            <Td>{demande.company_name}</Td>

                                            <Td>{demande.email}</Td>



                                            {demande.etat_demande === "Approuvée" &&
                                                <Td>
                                                    <Badge pr="1.5" pl="1.5" colorScheme={'green'} rounded={"lg"}> {t('Approuvée')}</Badge></Td>


                                            }
                                            {demande.etat_demande === "NonApprouvée" &&
                                                <Td>
                                                    <Badge pr="1.5" pl="1.5" colorScheme={'red'} rounded={"lg"}>{t('Non Approuvée')}</Badge></Td>
                                            }
                                            {demande.etat_demande === "EnCours" &&
                                                <Td>
                                                    <Badge pr="1.5" pl="1.5" colorScheme={'yellow'} rounded={"lg"}>{t("En cours")}</Badge>
                                                </Td>
                                            }


                                            {selectedEtat !== "Approuvée" ? <Td>{(!demande.approved_by && !demande.refused_by) ? "------" : demande.approved_by || demande.refused_by}</Td> : null}

                                            <Td>{format(demande.date_modification)}</Td>


                                            {demande.etat_demande === "NonApprouvée" ? (
                                                <Td>
                                                    <Button

                                                        size="sm"
                                                        colorScheme={'gray'}
                                                        onClick={() => {
                                                            handleModalEtat(demande.id);
                                                            setOverlay(<OverlayApprouver />);
                                                            onOpenModalA();
                                                        }}
                                                    >
                                                        {t("Réapprouver")}
                                                    </Button>
                                                </Td>
                                            ) : null}

                                            {demande.etat_demande === "Approuvée" ?
                                                <Td> <Button

                                                    size="sm"
                                                    isDisabled={true}
                                                    colorScheme={'linkedin'}
                                                    onClick={() => {
                                                        handleModalEtat(demande.id)
                                                        setOverlay(<OverlayApprouver />)
                                                        onOpenModalA()
                                                    }}
                                                >
                                                    {t("Approuver")}
                                                </Button>

                                                    <Button
                                                        ml='4'
                                                        size="sm"
                                                        isDisabled={true}
                                                        colorScheme={'red'}
                                                        onClick={() => {
                                                            handleModalEtat(demande.id)
                                                            setOverlayRefuser(<OverlayRefuser />)
                                                            onOpenModalB()
                                                        }}
                                                    >
                                                        {t("Refuser")}
                                                    </Button>

                                                </Td> : ""}

                                            {demande.etat_demande === "EnCours" ?
                                                <Td> <Button

                                                    size="sm"
                                                    // isDisabled={demande.etat ? false : true}
                                                    colorScheme={'linkedin'}
                                                    onClick={() => {
                                                        handleModalEtat(demande.id)
                                                        setOverlay(<OverlayApprouver />)
                                                        onOpenModalA()
                                                    }}
                                                >
                                                    {t("Approuver")}
                                                </Button>

                                                    <Button
                                                        ml='4'
                                                        size="sm"
                                                        // isDisabled={demande.etat ? false : true}
                                                        colorScheme={'red'}
                                                        onClick={() => {
                                                            handleModalEtat(demande.id)
                                                            setOverlayRefuser(<OverlayRefuser />)
                                                            onOpenModalB()
                                                        }}
                                                    >
                                                        {t("Refuser")}
                                                    </Button>

                                                </Td> : ""}

                                        </Tr>
                                    ))}
                                {(((searchTerm || selectedEtat !== null) && paginationData?.length === 0) || (checkLengthData)) && (
                                    <Tr>
                                        <Td colSpan={11}>{t("Aucune ligne correspondante n'a été trouvée.")}</Td>
                                    </Tr>
                                )}
                            </Tbody>
                        </Table>
                        {/* Start Pagination Component */}


                        <Flex flexDirection={{ base: 'column', lg: "row" }} justify="space-between" align="center" w="100%">
                            <Box flex="1">
                                <MyPagination
                                    paginationData={paginationData}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    PAGE_SIZE={pageSize}
                                />
                            </Box>

                            <Select w="70px" value={pageSize} onChange={handlePageSizeChange}>
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="40">40</option>
                                {/* Add more options as needed */}
                            </Select>
                        </Flex>
                        {/* End Pagination Modal Component */}
                    </TableContainer>
                </Stack>
            </Flex>

            {/* Start Approuver Modal Component */}
            <Modal isCentered isOpen={isModalAOpen} onClose={onCloseModalA}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{t("Approuver la demande d'inscription")}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text>{t("Voulez-vous vraiment approuver cette demande ?")}</Text>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            isLoading={isLoading}
                            size="sm"
                            mr={3}
                            colorScheme='gray'
                            onClick={() => handleApprouver(id)}
                        >
                            {isLoading ? <Spinner size="sm" /> : t("Approuver")}
                        </Button>
                        <Button colorScheme='red' size="sm" onClick={onCloseModalA}>{t("Fermer")}</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            {/* End Approuver Modal Component */}

            {/* Start Refuser Modal Component */}
            <Modal isCentered isOpen={isModalBOpen} onClose={onCloseModalB}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{t("Refuser la demande d'inscription")}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text>{t("Voulez-vous vraiment refuser cette demande ?")} </Text>
                        <Textarea value={messageRefus}
                            onChange={handleInputChange}
                            mt="2" placeholder={t('Laissez un message de justification')}>
                        </Textarea>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            isLoading={isLoading}
                            size="sm"
                            mr={3}
                            colorScheme='gray'
                            onClick={() => handleRefuser(id)}
                        >
                            {isLoading ? <Spinner size="sm" /> : "Refuser"}
                        </Button>
                        <Button colorScheme='red' size="sm" onClick={onCloseModalB}>{t("Fermer")}</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            {/* End Refuser Modal Component */}

        </Box>
    )
}