import React, { useEffect, useState } from 'react';
import {
    Box,
    Flex,
    Stack,
    Heading,
    Text,
    Container,
    Input,
    Button,
    SimpleGrid,
    Avatar,
    Alert,
    AlertIcon,
    AvatarGroup,
    useBreakpointValue,
    Textarea,
    Icon,
    useToast,
    Image,
    useColorModeValue,
    Select,
    HStack,
    FormControl,
    FormLabel,
    Checkbox
} from '@chakra-ui/react';
import { FaPlus } from 'react-icons/fa';
import axiosClient from '../../axios-client';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Resizer from "react-image-file-resizer";
import makeAnimated from 'react-select/animated';
import MySelect from "react-select";


const EditEvent = () => {

    const [data, setData] = useState([]);
    const [eventData, setEventData] = useState([]);
    const location = useLocation()
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [localisation, setLocalisation] = useState('');
    const [startDay, setStartDay] = useState();
    const [endDay, setEndDay] = useState();
    const { t } = useTranslation();
    const [isChecked, setIsChecked] = useState(false);

    const toast = useToast()
    const [selectedImg, setSelectedImg] = useState()

    const [selectedImage, setSelectedImage] = useState()
    const [image, setImage] = useState()
    const [message, setMessage] = useState('');
    const [partenairesNames, setPartenairesNames] = useState([]);
    const [selectedP, setSelectedP] = useState();

    const animatedComponents = makeAnimated();

    const [paysOptions, setPaysOptions] = useState([
        { value: '', label: '' },
    ]);
    const [cityOptions, setCityOptions] = useState([
        { value: '', label: '' },
    ]);
    const [selectedOptions, setSelectedOptions] = useState('');
    const [selectedCityOptions, setSelectedCityOptions] = useState('');

    const handlePaysSelectChange = (selectedOptions) => {
        setSelectedOptions(selectedOptions);
    };
    const handleCitySelectChange = (selectedOptions) => {
        setSelectedCityOptions(selectedOptions);
    };
    const navigate = useNavigate()

    const resizeFile = (file) => {
        return new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                300,
                300,
                "png",
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "blob"
            );
        });
    };
    const handleChangeImage = async (e) => {
        try {
            const file = e.target.files[0];
            setSelectedImg(file)
            const blobImage = await resizeFile(file);

            const fileName = file.name; // Use the original filename
            const imageFile = new File([blobImage], fileName, { type: blobImage.type });

            setSelectedImage(imageFile);
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        setIsChecked(eventData.isActive);
        axiosClient.get('events/')
            .then((res) => {
                setData(res.data.sort().reverse())
            })
            .catch((error) => {
                console.error(error);
            });


        if (selectedImg) {
            setImage(URL.createObjectURL(selectedImg))
        }
        else {
            setSelectedImg()
        }
        axiosClient.get('partenaires/get_partners_simple_data/')
            .then((response) => {
                // setPartenaires(response.data.length);
                setPartenairesNames(response.data)
                console.log();
            })
            .catch((error) => {
                console.log(error);
            });
        axiosClient.get(`/events/${location.state.idEvent}/`)
            .then((res) => {
                setEventData(res.data)
                console.log("event data", res.data);
                axiosClient.get('pays/')
                    .then(({ data }) => {
                        // setPays(data);

                        const paysOptions = data.map(pay => ({
                            value: pay.id,
                            label: pay.nom_pays,
                        }));

                        setPaysOptions([
                            ...paysOptions,
                        ]);
                    })
                    .catch((error) => {
                        console.error('Error fetching pays data:', error);
                    });
                axiosClient.get('villes/')
                    .then(({ data }) => {
                        const CityOptions = data.filter(e => e.pays === parseInt(selectedOptions ? selectedOptions.value : res.data?.pays)).map(city => ({
                            value: city.id,
                            label: city.nom_ville,
                        }));

                        setCityOptions([
                            ...CityOptions,
                        ]);
                    })
                    .catch((error) => {
                        console.error('Error fetching cities data:', error);
                    });
            })

    }, [selectedImg, selectedOptions, selectedCityOptions, eventData.isActive])


    const handlePSelection = (e) => {
        setSelectedP(e.target.value)
    };

    const ModifierEvent = (id) => {

        if (endDay < startDay) {
            setMessage(t("La date de début doit être inférieure à la date de fin de l'événement"))
            return;
        }
        const formData = new FormData();
        if (title) formData.append("title", title);
        if (description) formData.append("description", description);
        if (selectedImage) formData.append("image", selectedImage);
        if (localisation) formData.append("localisation", localisation);
        if (startDay) formData.append("start_day", startDay)
        if (endDay) formData.append("end_day", endDay)
        formData.append("isActive", isChecked)

        if (selectedP) formData.append("partenaire", selectedP)
        if (selectedOptions) formData.append("pays", selectedOptions.value)
        if (selectedCityOptions) formData.append("ville", selectedCityOptions.value)


        // const newList = data.filter((e) => e.id !== id)
        // if (title) {
        //     if (newList.length > 0) {
        //         const check = newList.find(obj => {
        //             return obj.name.toLowerCase() === title.toLowerCase()
        //         })
        //         if (check) {
        //             return setMessage(t('Cet événement existe déja !'))
        //         }
        //     }
        // }

        axiosClient.patch(`/events/${id}/`, formData).then(() => {


            toast({
                description: t(`L'événement est modifié avec succès`),
                status: 'success',
                duration: 2000,
                isClosable: true,
                variant: 'left-accent',
                position: 'bottom-right'
            })

            setMessage("")
            setSelectedImage()
            setImage(null)
            navigate("/liste-evenements")

        })


    }
    const affiche = useColorModeValue("gray.200", "gray.700")
    const borderColor = useColorModeValue("1px solid lightgray", "1px solid #5a5a5a")


    const colorbg = useColorModeValue('white', '#2d3748')

    const txtcolor = useColorModeValue('black', 'white')
    const colorbghover = useColorModeValue('#e2e8f0', '#171923')
    return (
        <Box

            padding={"0"}
            position={'relative'}
            overflow="hidden"
        >
            <Flex>
                <Box w="90%" ml={4} mt={7} >
                    <Text fontFamily='Plus Jakarta Display' as="cite" fontSize={{ base: "18px", md: "20px", lg: "25px" }} color={useColorModeValue('gray.600', "gray.300")}><span onClick={() => navigate('/')} style={{ cursor: 'pointer' }} onMouseEnter={e => e.target.style.textDecoration = "underline"} onMouseLeave={e => e.target.style.textDecoration = "none"} >{t("Accueil")}</span>  / <span onClick={() => navigate('/liste-evenements')} style={{ cursor: 'pointer' }} onMouseEnter={e => e.target.style.textDecoration = "underline"} onMouseLeave={e => e.target.style.textDecoration = "none"} > {t("Espace Événements")} </span> / <span  > {t("Modifier événement")} </span></Text>
                </Box>
            </Flex>
            <Container
                as={SimpleGrid}
                maxW={'7xl'}
                zIndex="1"
                columns={{ base: 1, lg: 2 }}
                spacing={{ base: 5, lg: 15 }}
                py={{ base: 10, sm: 10, lg: 10 }}
                position="relative"
                _before={{
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    filter: 'blur(110px)',
                    zIndex: -1,
                    background:
                        'radial-gradient(circle at 71px 61px, #F56565 111px, transparent 0), ' +
                        'radial-gradient(circle at 244px 106px, #ED64A6 139px, transparent 0), ' +
                        'radial-gradient(circle at 80.5px 189.5px, #ED8936 101.5px, transparent 0), ' +
                        'radial-gradient(circle at 196.5px 317.5px, #ECC94B 101.5px, transparent 0), ' +
                        'radial-gradient(circle at 70.5px 458.5px, #48BB78 101.5px, transparent 0), ' +
                        'radial-gradient(circle at 426.5px -0.5px, #4299E1 101.5px, transparent 0)',
                }}
            >
                <Stack
                    ml={{ base: 0, md: "5", xl: "10" }}
                    bg={useColorModeValue('rgb(237,237,237,0.6)', 'rgb(56,59,75,0.4)')}
                    shadow='xl'
                    rounded={'xl'}
                    p={{ base: 4, sm: 6, md: 8 }}
                    spacing={{ base: 8 }}
                    maxW={{ lg: 'xl' }}
                >
                    <Stack >
                        <Heading
                            lineHeight={1.1}
                            fontSize={{ base: '1xl', sm: '2xl', md: '3xl' }}
                        >
                            {t("Modifier l'événement")} {' '}
                            <Text as={'span'} bgGradient="linear(to-t, black,black)" bgClip="text">
                                !
                            </Text>
                        </Heading>

                    </Stack>
                    {message &&
                        <Alert fontSize={"sm"} padding={2} status='error' rounded="md">
                            <AlertIcon />
                            {message}
                        </Alert>
                    }
                    <Box as={'form'} >
                        <Stack spacing={5}>

                            <Input
                                maxLength={150}
                                defaultValue={eventData.title}
                                placeholder={t("Titre")}
                                bg={'rgb(189,190,191,0.1)'}
                                style={{ border: '1px solid rgb(189,190,191)' }}
                                _placeholder={{
                                    color: 'gray.500',
                                }}
                                onChange={e => setTitle(e.target.value)}
                            />
                            <Textarea
                                defaultValue={eventData.description}
                                placeholder="Description"
                                bg={'rgb(189,190,191,0.1)'}
                                style={{ border: '1px solid rgb(189,190,191)' }}
                                _placeholder={{
                                    color: 'gray.500',
                                }}
                                onChange={e => setDescription(e.target.value)}
                            />
                            <Input
                                maxLength={150}
                                defaultValue={eventData.localisation}
                                placeholder={t("Localisation")}
                                bg={'rgb(189,190,191,0.1)'}
                                style={{ border: '1px solid rgb(189,190,191)' }}
                                _placeholder={{
                                    color: 'gray.500',
                                }}
                                onChange={e => setLocalisation(e.target.value)}
                            />
                            <Flex gap={4} alignItems={{ base: "left", xl: 'center' }} direction={{ base: "column", lg: "row" }}>
                                <Text fontWeight={'semibold'} w={{ base: "100%", lg: "30%" }} >{t("Date de début")}</Text>

                                <Input
                                    w={{ base: "100%", lg: "70%" }}
                                    type='date'
                                    bg={'rgb(189,190,191,0.1)'}
                                    style={{ border: '1px solid rgb(189,190,191)' }}
                                    _placeholder={{
                                        color: 'gray.500',
                                    }}
                                    defaultValue={eventData.start_day}
                                    onChange={e => setStartDay(e.target.value)}
                                    min={new Date().toISOString().split('T')[0]}
                                    onKeyDown={(e) => e.preventDefault()}
                                />
                            </Flex>

                            <Flex gap={4} alignItems={{ base: "left", xl: 'center' }} direction={{ base: "column", lg: "row" }}>
                                <Text fontWeight={'semibold'} w={{ base: "100%", lg: "30%" }} >{t("Date de fin")} </Text>

                                <Input
                                    w={{ base: "100%", lg: "70%" }}
                                    type='date'
                                    bg={'rgb(189,190,191,0.1)'}
                                    style={{ border: '1px solid rgb(189,190,191)' }}
                                    _placeholder={{
                                        color: 'gray.500',
                                    }}
                                    defaultValue={eventData.end_day}
                                    onChange={e => setEndDay(e.target.value)}
                                    min={new Date().toISOString().split('T')[0]}
                                    onKeyDown={(e) => e.preventDefault()}
                                /></Flex>
                            <Select
                                rounded={"lg"}
                                value={selectedP ? selectedP : eventData.partenaire}
                                placeholder={t("Selectionez un partenaire")}
                                onChange={handlePSelection}
                                style={{ border: '1px solid rgb(189,190,191)' }}
                                bg={'rgb(189,190,191,0.1)'}
                            >

                                {partenairesNames?.map((prt) => (
                                    <option key={prt.id} value={prt.id}>
                                        {prt.company_name}
                                    </option>
                                ))}
                            </Select>
                            <HStack spacing={2}>

                                <Box w="50%">
                                    <FormControl id="pays" >
                                        <FormLabel ml={0.5}>{t("Pays")}</FormLabel>

                                        {/* <Input  onChange={(e) => setPays(e.target.value)} name="pays" type="text" placeholder='Tapez le pays' maxLength={30} /> */}
                                        {/* <Select
                    borderColor={inputColor}

                    id="pays"
                    name="pays"
                    defaultValue={selectedCountry}
                    onChange={handleCountryChange}
                    isSearchable
                    bg={colorbg}
                    color={txtcolor}
                    _focus={{ bg: colorbg, borderColor: "transparent" }}
                >
                    <option>-- {t("Choisir un pays")} --</option>
                    {countries.sort((a, b) => a.label.localeCompare(b.label)).map((country) => (
                        <option
                            key={country.value}
                            value={country.value}
                            bg={colorbg}
                            color={txtcolor}
                            _hover={{ bg: colorbghover, cursor: "pointer" }}
                        >
                            {country.label}
                        </option>
                    ))}
                </Select> */}
                                        <MySelect
                                            value={selectedOptions ? selectedOptions : paysOptions.find(option => eventData.pays === parseInt(option.value))}
                                            onChange={handlePaysSelectChange}
                                            options={paysOptions}
                                            closeMenuOnSelect={true}
                                            components={animatedComponents}
                                            style={{ border: '1px solid rgb(189,190,191)' }}
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    backgroundColor: 'rgb(189,190,191,0.1)',
                                                }),
                                                menu: (provided) => ({
                                                    ...provided,
                                                    textAlign: 'left',
                                                    backgroundColor: colorbg,
                                                    cursor: "pointer"
                                                }),

                                                option: (provided, state) => ({
                                                    ...provided,
                                                    color: txtcolor,
                                                    backgroundColor: state.isSelected ? colorbg : 'inherit',
                                                    '&:hover': {
                                                        backgroundColor: colorbghover,
                                                    },
                                                    cursor: "pointer"
                                                }),
                                                singleValue: (provided) => ({
                                                    ...provided,
                                                    color: txtcolor
                                                }),
                                            }}
                                        />
                                    </FormControl>
                                </Box>
                                <Box w="50%">
                                    <FormControl id="ville">
                                        <FormLabel ml={0.5}>{t("Ville")}</FormLabel>
                                        {/* <Input value={ville} onChange={(e) => setVille(e.target.value)} name="ville" type="text" placeholder={t('Tapez la ville')} maxLength={30} /> */}
                                        <MySelect
                                            value={selectedCityOptions ? selectedCityOptions : cityOptions.find(option => eventData.ville === parseInt(option.value))}
                                            onChange={handleCitySelectChange}
                                            options={cityOptions}
                                            closeMenuOnSelect={true}
                                            components={animatedComponents}
                                            style={{ border: '1px solid rgb(189,190,191)' }}


                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    backgroundColor: 'rgb(189,190,191,0.1)',
                                                }),
                                                menu: (provided) => ({
                                                    ...provided,
                                                    textAlign: 'left',
                                                    backgroundColor: colorbg,
                                                    cursor: "pointer"
                                                }),


                                                option: (provided, state) => ({
                                                    ...provided,
                                                    color: txtcolor,
                                                    backgroundColor: state.isSelected ? colorbg : 'inherit',
                                                    '&:hover': {
                                                        backgroundColor: colorbghover,
                                                    },
                                                    cursor: "pointer"
                                                }),
                                                singleValue: (provided) => ({
                                                    ...provided,
                                                    color: txtcolor
                                                }),
                                            }}
                                        />
                                    </FormControl>
                                </Box>
                            </HStack>
                            <Checkbox
                                sx={{
                                    '& .chakra-checkbox__control': {
                                        bg: isChecked ? 'blue.500' : 'rgb(189,190,191,0.1)', // Change the background color based on the checked state
                                        borderColor: isChecked ? 'blue.500' : 'gray.300',
                                    },
                                    '& .chakra-checkbox__control[data-checked]': {
                                        bg: 'blue.500', // Background color when checked
                                        borderColor: 'blue.500',
                                    }
                                }}
                                isChecked={isChecked}
                                onChange={e => {
                                    setIsChecked(e.target.checked);
                                    console.log('checked', e.target.checked);
                                }}
                            >
                                {t("Activé")}
                            </Checkbox>
                        </Stack>
                        <Button
                            fontFamily={'heading'}
                            mt={8}
                            w={'full'}
                            bgGradient="linear(to-l, gray.600,black)"
                            color={'white'}
                            _hover={{
                                bgGradient: 'linear(to-l, gray.600,black)',
                                boxShadow: 'xl',
                            }}
                            onClick={(e) => ModifierEvent(eventData.id)}
                        >
                            {t("Modifier")}
                        </Button>
                    </Box>
                </Stack>
                <Stack ml={{ base: 0, md: 10, lg: 0 }} px={{ base: 0, lg: 10, xl: 20 }} spacing={{ base: 10, lg: 20 }}>

                    {image || eventData.image ?
                        <label style={{ height: "100%" }} htmlFor="imageInput">
                            <Avatar
                                src={image ? image : eventData.image ? eventData.image : eventData.image}
                                as="label"
                                htmlFor="imageInput"
                                p="0px"
                                bg={affiche}
                                color="gray.500"
                                border={borderColor}
                                borderRadius="15px"
                                height="100%"
                                w={'100%'}
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                alignItems="center"
                                cursor="pointer"
                            >

                                <input
                                    id="imageInput"
                                    type="file"
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    onChange={handleChangeImage}
                                />
                            </Avatar>
                        </label> :
                        <label style={{ height: "100%" }} htmlFor="imageInput">
                            <Image
                                src={image}
                                as="label"
                                htmlFor="imageInput"
                                p="0px"
                                bg="gray.200"
                                color={affiche}
                                border={borderColor}
                                borderRadius="15px"
                                height="100%"
                                w={'100%'}
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                alignItems="center"
                                cursor="pointer"
                            >
                                {!image &&
                                    <>
                                        <Icon as={FaPlus} fontSize="lg" mb="12px" />
                                        <Text fontSize="lg" fontWeight="bold">
                                            {t("Ajouter l'affiche")}
                                        </Text></>}

                                <input
                                    id="imageInput"
                                    type="file"
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    onChange={handleChangeImage}
                                />
                            </Image>
                        </label>}



                </Stack>

            </Container>

        </Box>
    );
}

export default EditEvent;
