import logo from '../../assets/images/logo.png';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { createRef, useState } from 'react';
import { useStateContext } from '../../context/ContextProvider';
import axiosClient from '../../axios-client';
import login from '../../assets/images/logo.png'

import {
    InputGroup,
    InputRightElement,
    Spinner,
    useToast,
    Stack,
    Alert,
    AlertIcon,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';


import React from "react";
// Chakra imports
import {
    Box,
    Flex,
    Button,
    FormControl,
    FormLabel,
    Heading,
    Input,
    Link,
    Switch,
    Text,
    DarkMode,
} from "@chakra-ui/react";

// Assets
import signInImage from "../../assets/images/signInImage.jpg";
import { useTranslation } from 'react-i18next';


export default function Login() {
    const { t } = useTranslation();

    const titleColor = "white";
    const textColor = "gray.400";
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const { setToken, isLoading, setIsLoading } = useStateContext()
    const [message, setMessage] = useState([])
    const toast = useToast()
    const navigate = useNavigate()


    //encryption function
    function encryptString(plainText, shift) {
        if (!Number.isInteger(shift) || shift < 1 || shift > 25) {
            throw new Error("Shift must be an integer between 1 and 25.");
        }

        const encryptedArray = [];
        for (let i = 0; i < plainText.length; i++) {
            let charCode = plainText.charCodeAt(i);

            // Encrypt uppercase letters
            if (charCode >= 65 && charCode <= 90) {
                charCode = ((charCode - 65 + shift) % 26) + 65;
            }
            // Encrypt lowercase letters
            else if (charCode >= 97 && charCode <= 122) {
                charCode = ((charCode - 97 + shift) % 26) + 97;
            }

            encryptedArray.push(String.fromCharCode(charCode));
        }

        return encryptedArray.join("");
    }


    const onSubmit = ev => {
        toast.closeAll()
        ev.preventDefault()
        setIsLoading(true)
        const payload = {
            email,
            password,
        }

        axiosClient.post('token/admin/', payload)
            .then(({ data }) => {
                setToken(data.access);
                setIsLoading(false);
                // localStorage.setItem("mutumAdmin", encryptString(email, 25))
                // localStorage.setItem("tsaroAdmin", encryptString(password, 25))
                localStorage.setItem("role", data.role)
                
                // if (data.role === 'RESPO') {
                //     console.log(data.role)
                //     navigate("/formations")
                //     return;
                // }
                axiosClient.get("auth/user/")
                    .then((res) => {
                        axiosClient.put(`update_tokens_list/${res.data.id}/`, { token: data.access })
                            .then(() => {
                                console.log('Token updated successfully')
                                localStorage.setItem("loggedin", true)
                            })
                            .catch(err => console.log(err));
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            })
            .catch((err) => {
                const response = err.response;
                if (response.data.message === "Mot de passe incorrect") {
                    setMessage({ alert: t(response.data.message), target: "password" });
                }
                else if (response.data.message === "Votre compte est désactivé") { setMessage({ alert: t(response.data.message), target: "" }); }
                else {
                    setMessage({ alert: t("Email incorrect"), target: "email" });
                }

                setIsLoading(false)
                console.error("erorrrrrrrrrrrr: ", err);
            })

    }
    const [showPassword, setShowPassword] = useState(false);
    const isFormFilled = email && password;

    return (
        <Flex position='relative' bg="gray.800">
            <Flex
                minH='100vh'
                h={{ base: "120vh", lg: "fit-content" }}
                w='100%'
                maxW='1044px'
                mx='auto'
                pt={{ sm: "100px", md: "0px" }}
                flexDirection='column'
                me={{ base: "auto", lg: "50px", xl: "auto" }}>
                <Flex

                    alignItems='center'
                    justifyContent='center'
                    style={{ userSelect: "none" }}
                    mx={{ base: "auto", lg: "unset" }}
                    ms={{ base: "auto", lg: "auto" }}
                    w={{ base: "100%", md: "50%", lg: "450px" }}
                    px='50px'>
                    <Flex
                        direction='column'
                        w='100%'
                        background='transparent'
                        mt={{ base: "50px", md: "150px", lg: "160px", xl: "150px" }}
                        mb={{ base: "60px", lg: "95px" }}>
                        <Heading color={titleColor} fontSize='32px' mb='20px'>
                            {t("Espace administratif")}
                        </Heading>
                        <Text
                            mb='36px'
                            ms='4px'
                            color={textColor}
                            fontWeight='bold'
                            fontSize='14px'>
                            {t("Entrez votre adresse e-mail et votre mot de passe pour se connecter")}
                        </Text>
                        {message.alert &&
                            <Alert status='error' rounded="md">
                                <AlertIcon />
                                {message.alert}
                            </Alert>
                        }
                        <FormControl mb="3" id="email" isRequired >
                            <FormLabel color={titleColor} >{t("Adresse email")}</FormLabel>
                            <Input color={titleColor} onChange={(e) => setEmail(e.target.value)} type="text" placeholder={t('Tapez votre adresse email')} />
                        </FormControl>
                        <FormControl id="password" isRequired isInvalid={message.target === "password"}>
                            <FormLabel mb="3" color={titleColor}>{t("Mot de passe")}</FormLabel>
                            <InputGroup mb="3">
                                <Input color={titleColor} onChange={(e) => setPassword(e.target.value)} type={showPassword ? 'text' : 'password'} placeholder={t('Tapez votre mot de passe')} />
                                <InputRightElement h={'full'}>
                                    <Button
                                        color={titleColor}
                                        variant={'ghost'}
                                        _hover='none'
                                        onClick={() =>
                                            setShowPassword((showPassword) => !showPassword)
                                        }>
                                        {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                                    </Button>
                                </InputRightElement>
                            </InputGroup>
                        </FormControl>
                        {/* <FormControl>
                            <FormLabel
                                ms='4px'
                                fontSize='sm'
                                fontWeight='normal'
                                color='white'>
                                Email
                            </FormLabel>

                            <Input
                                color='white'
                                bg='rgb(19,21,54)'
                                border='transparent'
                                borderRadius='20px'
                                fontSize='sm'
                                size='lg'
                                w={{ base: "100%", md: "346px" }}
                                maxW='100%'
                                h='46px'
                                placeholder='Your email adress'
                            />
                        </FormControl> */}
                        {/* <FormControl>
                            <FormLabel
                                ms='4px'
                                fontSize='sm'
                                fontWeight='normal'
                                color='white'>
                                Password
                            </FormLabel>

                            <Input
                                color='white'
                                bg='rgb(19,21,54)'
                                border='transparent'
                                borderRadius='20px'
                                fontSize='sm'
                                size='lg'
                                w={{ base: "100%", md: "346px" }}
                                maxW='100%'
                                type='password'
                                placeholder='Your password'
                            />
                        </FormControl> */}


                        <Stack spacing={10}>
                            <Stack
                                direction={{ base: 'column', sm: 'row' }}
                                align={'start'}
                                justify={'space-between'}>
                                <Link href='/reset_password' color={'#49b1cc'}>{t('Mot de passe oublié ?')}</Link>
                            </Stack>
                            <Button
                                fontSize='13px'
                                type="submit" onClick={onSubmit} isLoading={isLoading}
                                colorScheme='linkedin' isDisabled={!isFormFilled}>
                                {isLoading ? <Spinner size="sm" /> : t("Connexion")}
                            </Button>
                        </Stack>


                    </Flex>
                </Flex>
                {/* <Box
          w={{ base: "335px", md: "450px" }}
          mx={{ base: "auto", lg: "unset" }}
          ms={{ base: "auto", lg: "auto" }}
          mb='80px'>
          <AuthFooter />
        </Box> */}
                <Box
                    display={{ base: "none", lg: "block" }}
                    overflowX='hidden'
                    h='100%'
                    maxW={{ md: "50vw", lg: "50vw" }}
                    minH='100vh'
                    w='960px'
                    position='absolute'
                    left='0px'>
                    <Box
                        bgImage={signInImage}
                        w='100%'
                        h='100%'
                        bgSize='cover'
                        bgPosition='50%'
                        position='absolute'
                        display='flex'
                        flexDirection='column'
                        justifyContent='center'
                        alignItems='center'
                    >
                        <Text
                            mb={2}
                            textAlign='center'
                            color='white'
                            letterSpacing='8px'
                            fontSize='20px'
                            fontWeight='500'>
                            {t("BIENVENU DANS APA ADMIN")}
                        </Text>
                        <Text
                            textAlign='center'
                            color='transparent'
                            letterSpacing='10px'
                            fontSize='36px'
                            fontFamily="initial"
                            fontWeight='bold'
                            bgClip='text !important'
                            bg='linear-gradient(94.56deg, #FFFFFF 79.99%, #21242F 102.65%)'>
                            APA PREMIUM
                        </Text>
                    </Box>
                </Box>
            </Flex>
        </Flex>
    );
}

