import React, { useCallback, useEffect, useState } from 'react'
import { useStateContext } from '../../context/ContextProvider';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Avatar, Badge, Box, Button, Card, CardBody, Container, Flex, Grid, Image, Input, InputGroup, InputLeftElement, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, SimpleGrid, Skeleton, SkeletonCircle, Stack, Switch, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue, useDisclosure, useToast } from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import axiosClient, { linkImage } from '../../axios-client';
import MyPaginantion from '../../components/MyPagination';
import img from '../../assets/images/tracking.png'
import { GoAlertFill } from "react-icons/go";
import MyPagination from '../../components/MyPagination';

function GestPartenairesSignales() {
    const [loadingData, setLoadingData] = useState(true)
    const [checkLengthData, setCheckLengthData] = useState(false)
    const { user } = useStateContext();
    const navigate = useNavigate()
    const { t } = useTranslation();
    const toast = useToast()
    const { isOpen, onOpen, onClose } = useDisclosure()

    const [selectedEtat, setSelectedEtat] = useState(null);

    const [listPartenaires, setListPartenaires] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [pays, setPays] = useState([])
    const [currentPage, setCurrentPage] = useState(0);
    const [partenaire, setPartenaire] = useState();
    const [reports, setReports] = useState([])

    const [showMore, setShowMore] = useState(-1)
    const [paginationData, setPaginationData] = useState()


    ////////////////////////////////the size of the table//////////////////////////////
    const PAGE_SIZE = 10;
    const [pageSize, setPageSize] = useState(PAGE_SIZE);
    const handlePageSizeChange = (event) => {
        const newSize = parseInt(event.target.value, 10);
        setPageSize(newSize);
        setCurrentPage(0);
    };
    //////////////////////////////////////////////////////////////////////////////////


    useEffect(() => {
        // setLoadingData(true)

        axiosClient.get(`/partenaires/get_reported_partners/?page_size=${pageSize}&page=${currentPage + 1}&search_term=${searchTerm}&etat=${selectedEtat}`)
            .then((response) => {
                let filteredPartners = response.data.results
                setPaginationData(response.data);

                if (filteredPartners.length === 0) {
                    setCheckLengthData(true)
                }
                else {
                    setListPartenaires(filteredPartners);
                }
                setLoadingData(false)
            })
            .catch((error) => {
                console.error(error);
                setLoadingData(false)
            });
        axiosClient.get('/reports/')
            .then((res) => {
                setReports(res.data)
            })
            .catch((error) => {
                console.error(error);
            })

        // axiosClient.get('/partenaires/')
        //     .then((response) => {
        //         axiosClient.get('/reports/').then((res) => {
        //             setReports(res.data)
        //             const reportedPartnersIds = res.data.map(report => report.partenaire);

        //             // Counting occurrences of each partner ID
        //             const partnerReportCounts = reportedPartnersIds.reduce((acc, partnerId) => {
        //                 acc[partnerId] = (acc[partnerId] || 0) + 1;
        //                 return acc;
        //             }, {});

        //             // Filtering partners based on whether their ID appears in the reports data
        //             const filteredPartners = response.data.filter(partner => reportedPartnersIds.includes(partner.id));

        //             filteredPartners.map((row) => {
        //                 console.log("data reduce : ", partnerReportCounts[row.id]);

        //             });
        //             // Sorting filtered partners based on the number of reports (from higher to lower)
        //             filteredPartners.sort((a, b) => {
        //                 const reportsA = partnerReportCounts[a.id] || 0;
        //                 const reportsB = partnerReportCounts[b.id] || 0;
        //                 return reportsB - reportsA;
        //             });
        //             if (filteredPartners.length === 0) {
        //                 setCheckLengthData(true)
        //             }
        //             else {
        //                 setListPartenaires(filteredPartners);
        //             }
        //             setLoadingData(false)
        //         })
        //     })
        //     .catch((error) => {
        //         console.error(error);
        //     });    
        axiosClient.get('pays/')
            .then((res) => {
                setPays(res.data);
            })
            .catch((error) => {
                console.error(error);
            });

    }, [selectedEtat, pageSize, currentPage, searchTerm]);

    // const filteredData = useCallback(() => {
    //     return listPartenaires.filter((row) => {
    //         // Exclude data of current user
    //         if (row.id === user.id || row.role === "SUPERADMIN") {
    //             return false;
    //         }
    //         if (row.role === "ADMIN" && (user.role !== "SUPERADMIN")) {
    //             return false;
    //         }
    //         return (
    //             row.company_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //             row.pays.toLowerCase().includes(searchTerm.toLowerCase())
    //         );
    //     });
    // }, [listPartenaires, searchTerm, user.id]);


    const handleEtatClick = (id, etat) => {
        const formData = new FormData();
        formData.append("etat", !etat);
        formData.append("edited_by", user.first_name + ' ' + user.last_name);

        axiosClient.put(`/update_partenaire_etat/${id}/`, formData)
            .then((response) => {
                setListPartenaires(rows => rows.map(row => {
                    if (row.id === id) {
                        return {
                            ...row,
                            etat: !etat,
                        };
                    }
                    return row;
                }));
            })
            .catch((error) => console.error(error));
        if (!etat) {
            toast({
                description: t("le compte est activé"),
                variant: 'left-accent',
                status: 'success',
                duration: 2000,
                isClosable: true,
                position: 'bottom-right'
            })
        }
        else {
            toast({
                description: t("le compte est désactivé"),
                status: 'warning',
                variant: 'left-accent',
                duration: 2000,
                isClosable: true,
                position: 'bottom-right'
            })
        }
    }
    const shadow = useColorModeValue("2px 2px 10px lightgray", "2px 2px 10px #2d3748")

    return (
        <Box
            padding={"0"}
            w={"100%"}
            minH={"100vh"}
            position={'relative'}
            overflow="hidden">

            <Flex>
                <Box w="100%" ml={4} mr={5} mt={7} display={"flex"} justifyContent="space-between" alignItems={'center'}>
                    <Text fontFamily='Plus Jakarta Display' as="cite" fontSize={{ base: "18px", md: "20px", lg: "25px" }} color="gray"><span onClick={() => navigate('/')} style={{ cursor: 'pointer' }} onMouseEnter={e => e.target.style.textDecoration = "underline"} onMouseLeave={e => e.target.style.textDecoration = "none"} >{t("Accueil")}</span>  / <span  >{t("Partenaires signalés")}</span></Text>
                    {/* <GoAlertFill /> */}
                    <Image zIndex={999} w={{ base: 10, md: 20 }} h={{ base: 10, md: 20 }} src={img} />

                </Box>

            </Flex>
            <Flex
                px={5}
                as={SimpleGrid}
                maxW={'7xl'}
                zIndex="1"
                columns={{ base: 1, lg: 2 }}
                spacing={{ base: 5, lg: 15 }}
                py={{ base: 5, sm: 5, lg: 5 }}
                position="relative"
                _before={{

                    content: '""',
                    position: 'absolute',
                    top: -40,
                    right: -20,
                    width: '30%',
                    minH: '100vh',
                    filter: 'blur(140px)',
                    zIndex: -1,
                    background:
                        'radial-gradient(circle at 71px 61px, #FF0000 111px, transparent 0), ' +
                        'radial-gradient(circle at 244px 106px, #FFA79F 140px, transparent 0), ' +
                        'radial-gradient(circle at 80.5px 189.5px, #FF2929 101.5px, transparent 0), ' +
                        'radial-gradient(circle at 196.5px 317.5px, #FF2929 101.5px, transparent 0), ' +
                        'radial-gradient(circle at 70.5px 458.5px, #FFA79F 101.5px, transparent 0), ' +
                        'radial-gradient(circle at 426.5px -0.5px, #FFFFFF 101.5px, transparent 0)',
                }}

            >

                <Stack
                    w={'full'}
                    maxW='full'
                    bg={useColorModeValue('white', 'gray.700')}
                    rounded={'lg'}
                    p={{ base: 4, sm: 6, md: 8 }}
                >
                    {/* Search input */}
                    <Flex
                        justifyContent="space-between"
                        alignItems="center"
                        gap={2}
                        // direction={{ base: 'column', lg: 'row' }}
                    >
                            <Select borderRadius="5" size="sm" fontSize={{ base: 13, lg: 16 }} w={{ base: '30%', lg: "20%" }}  mb={4} value={selectedEtat} onChange={(e) => setSelectedEtat(e.target.value === '' ? null : e.target.value === 'true')}>
                                <option value="">{t("Tout")}</option>
                                <option value="true">{t("Activé")}</option>
                                <option value="false">{t("Désactivé")}</option>
                            </Select>


                        <InputGroup size="sm" w={{ base: '70%', lg: "40%" }}>
                            <InputLeftElement
                                pointerEvents='none'
                                children={<SearchIcon color='gray.300' />}
                            />
                            <Input
                                borderRadius="5"
                                placeholder={t("Recherche...")}
                                value={searchTerm}
                                onChange={(e) => {setCurrentPage(0)
                                    setSearchTerm(e.target.value)}}
                                mb={4}
                                sx={{
                                    marginLeft: 'auto',
                                }}
                            />  </InputGroup>

                    </Flex>
                    <TableContainer>
                        <Table size="sm" variant='striped' colorScheme="gray">
                            <Thead size="sm">
                                <Tr fontSize="12">
                                    <Th  fontFamily='Plus Jakarta Display' fontSize="12">Image</Th>
                                    {/* <Th fontFamily='Plus Jakarta Display' fontSize="12">Code</Th> */}
                                    <Th fontFamily='Plus Jakarta Display' fontSize="12">{t('Nom entreprise')}</Th>

                                    {/* <Th fontFamily='Plus Jakarta Display' fontSize="12">{t("Nom")}</Th>
                                    <Th fontFamily='Plus Jakarta Display' fontSize="12">{t("Prénom")}</Th> */}
                                    {/* <Th fontFamily='Plus Jakarta Display' fontSize="12"> Email</Th> */}
                                    {/* <Th fontFamily='Plus Jakarta Display' fontSize="12">{t("Adresse")}</Th> */}

                                    <Th fontFamily='Plus Jakarta Display' fontSize="12">{t("Pays")}</Th>
                                    <Th fontFamily='Plus Jakarta Display' fontSize="12">{t("Nombre de réclamations")}</Th>

                                    <Th fontFamily='Plus Jakarta Display' fontSize="12">{t("État")}</Th>
                                    <Th fontFamily='Plus Jakarta Display' fontSize="12">{t("Réclamations")}</Th>

                                    {/* <Th fontFamily='Plus Jakarta Display' fontSize="12">{t("Date Création")}</Th> */}

                                </Tr>
                            </Thead>
                            <Tbody>
                                {loadingData &&
                                    <Tr>
                                        <Td><SkeletonCircle size='10' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>

                                    </Tr>}

                                {(paginationData?.length !== 0) &&
                                    listPartenaires.map((row) => (<Tr key={row.id}>
                                        <Td > <Avatar size='md' src={linkImage + row.image} /></Td>
                                        {/* <Td >{row.code}</Td> */}
                                        <Td >{row.company_name}</Td>
                                        {/* <Td>{row.last_name}</Td>
                                        <Td>{row.first_name}</Td> */}
                                        {/* <Td >{row.email}</Td> */}
                                        {/* <Td>
                                            <Tooltip label={row.rue + " " + row.ville + " " + row.code_postal} closeOnClick={true}>
                                                {truncateAddress(row.rue + " " + row.ville + " " + row.code_postal)}
                                            </Tooltip>
                                        </Td> */}
                                        <Td>{row.pays_partenaire}</Td>
                                        <Td>
                                            <Badge p={1} px={3} rounded={"md"} variant='outline' colorScheme='red' > {t("Signalé")} {row.report_count} {t("foix")}</Badge>

                                        </Td>
                                        <Td>
                                            <Flex>
                                                <Stack direction='row' mr="3px">
                                                    {(user.role === "SUPERADMIN" || user.role === "ADMIN") &&
                                                        <Switch
                                                            colorScheme='green'
                                                            isChecked={row.etat}
                                                            onChange={() => handleEtatClick(row.id, row.etat)}
                                                        />
                                                    }
                                                </Stack>
                                                <Badge
                                                    size="sm"
                                                    rounded={'lg'}
                                                    colorScheme={!row.etat ? 'red' : 'green'}
                                                >
                                                    {row.etat ? t("Activé") : t("Désactivé")}
                                                </Badge>
                                            </Flex>
                                        </Td>
                                        <Td> <Button onClick={() => {
                                            setPartenaire(row.id)
                                            onOpen()
                                        }} colorScheme='red'>{t("Réclamations")}</Button> </Td>
                                        {/* <Td>{new Date(row.date_creation).toLocaleDateString(t("fr-FR"))}</Td> */}



                                    </Tr>
                                    ))}
                                {(((searchTerm || selectedEtat !== null) && paginationData?.length === 0) || (checkLengthData) || paginationData?.length === 0) && (
                                    <Tr>
                                        <Td colSpan={11}>{t("Aucune ligne correspondante n'a été trouvée.")}</Td>
                                    </Tr>
                                )}
                            </Tbody>
                        </Table>

                    </TableContainer>
                    {/* Pagination */}
                    <Flex flexDirection={{base:'column',lg:"row"}} justify="space-between" align="center" w="100%">
                        <Box flex="1">
                            <MyPagination
                                paginationData={paginationData}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                PAGE_SIZE={pageSize}
                            />
                        </Box>

                        <Select w="70px" value={pageSize} onChange={handlePageSizeChange}>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="40">40</option>
                            <option value="50">50</option>

                            {/* Add more options as needed */}
                        </Select>
                    </Flex>
                </Stack>

            </Flex>
            <Modal onClose={onClose} size={'xl'} isOpen={isOpen}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{t("Réclamations des clients")}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Accordion defaultIndex={reports.find(e => e.partenaire === partenaire && e.choice !== "Autre") ? [0] : [1]} >
                            <AccordionItem bg={useColorModeValue('gray.50', 'gray.800')}>
                                <h2>
                                    <AccordionButton>
                                        <Box display={'flex'} gap={2} justifyContent={'space-between'} as="span" flex='1' textAlign='left'>
                                            <Text fontWeight={'semibold'}>
                                                {t("Choix sélectionnées")}
                                            </Text>

                                            <Badge p={1} px={2} rounded={"md"} variant='outline' colorScheme='red' >{reports.filter(e => e.partenaire === partenaire && e.choice !== "Autre").length}</Badge>

                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel overflow={"auto"} maxH={"250"} pb={4}>

                                    {reports.find(e => e.partenaire === partenaire && e.choice === "Service_peu_fiable") &&
                                        <Card mb={2} boxShadow={shadow}>
                                            <CardBody gap={2} display={'flex'}>
                                                <Text> {t("Service peu fiable")} </Text>
                                                <Badge p={1} px={2} rounded={"md"} variant='subtle' colorScheme='red' >{reports.filter(e => e.partenaire === partenaire && e.choice === "Service_peu_fiable").length}</Badge>

                                            </CardBody>
                                        </Card>}
                                    {reports.find(e => e.partenaire === partenaire && e.choice === "Service_de_mauvaise_qualité") &&
                                        <Card mb={2} boxShadow={shadow}>
                                            <CardBody gap={2} display={'flex'}>
                                                <Text> {t("Service de mauvaise qualité")} </Text>
                                                <Badge p={1} px={2} rounded={"md"} variant='subtle' colorScheme='red' >{reports.filter(e => e.partenaire === partenaire && e.choice === "Service_de_mauvaise_qualité").length}</Badge>

                                            </CardBody>
                                        </Card>}
                                    {reports.find(e => e.partenaire === partenaire && e.choice === "Fausse_représentation_des_offres") &&
                                        <Card mb={2} boxShadow={shadow}>
                                            <CardBody gap={2} display={'flex'}>
                                                <Text> {t("Fausse représentation des offres")} </Text>
                                                <Badge p={1} px={2} rounded={"md"} variant='subtle' colorScheme='red' >{reports.filter(e => e.partenaire === partenaire && e.choice === "Fausse_représentation_des_offres").length}</Badge>

                                            </CardBody>
                                        </Card>}
                                    {reports.find(e => e.partenaire === partenaire && e.choice === "Conduite_non_professionnelle") &&
                                        <Card mb={2} boxShadow={shadow}>
                                            <CardBody gap={2} display={'flex'}>
                                                <Text> {t("Conduite non professionnelle")} </Text>
                                                <Badge p={1} px={2} rounded={"md"} variant='subtle' colorScheme='red' >{reports.filter(e => e.partenaire === partenaire && e.choice === "Conduite_non_professionnelle").length}</Badge>

                                            </CardBody>
                                        </Card>}
                                    {reports.find(e => e.partenaire === partenaire && e.choice === "Discrépances_de_facturation") &&
                                        <Card mb={2} boxShadow={shadow}>
                                            <CardBody gap={2} display={'flex'}>
                                                <Text> {t("Discrépances de facturation")} </Text>
                                                <Badge p={1} px={2} rounded={"md"} variant='subtle' colorScheme='red' >{reports.filter(e => e.partenaire === partenaire && e.choice === "Discrépances_de_facturation").length}</Badge>
                                            </CardBody>
                                        </Card>}
                                    {reports.find(e => e.partenaire === partenaire && e.choice === "Mauvaise_communication") &&
                                        <Card mb={2} boxShadow={shadow}>
                                            <CardBody gap={2} display={'flex'}>
                                                <Text> {t("Mauvaise communication")} </Text>
                                                <Badge p={1} px={2} rounded={"md"} variant='subtle' colorScheme='red' >{reports.filter(e => e.partenaire === partenaire && e.choice === "Mauvaise_communication").length}</Badge>

                                            </CardBody>
                                        </Card>}
                                    {reports.find(e => e.partenaire === partenaire && e.choice === "Résultats_insatisfaisants") &&
                                        <Card mb={2} boxShadow={shadow}>
                                            <CardBody gap={2} display={'flex'}>
                                                <Text> {t("Résultats insatisfaisants")} </Text>
                                                <Badge p={1} px={2} rounded={"md"} variant='subtle' colorScheme='red' >{reports.filter(e => e.partenaire === partenaire && e.choice === "Résultats_insatisfaisants").length}</Badge>

                                            </CardBody>
                                        </Card>}


                                </AccordionPanel>
                            </AccordionItem>

                            <AccordionItem mt={2} bg={useColorModeValue('gray.50', 'gray.800')}>
                                <h2>
                                    <AccordionButton>
                                        <Box display={'flex'} justifyContent={'space-between'} gap={2} as="span" flex='1' textAlign='left'>
                                            <Text fontWeight={'semibold'}>
                                                {t("Message des clients")}
                                            </Text>
                                            <Badge p={1} px={2} rounded={"md"} variant='outline' colorScheme='red' >{reports.filter(e => e.partenaire === partenaire && e.choice === "Autre").length}</Badge>


                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel overflow={"auto"} maxH={"250"} pb={4}>
                                    {reports.filter(e => e.partenaire === partenaire && e.choice === "Autre").map((row) => (
                                        <Card mb={2} boxShadow={shadow}>
                                            <CardBody>
                                                <Text>{row.message.length > 100 ? <>
                                                    {row.message.substring(0, showMore === row.id ? 255 : 100)}
                                                    {showMore === row.id
                                                        ? <span style={{ cursor: 'pointer', textDecoration: 'underline', color: "gray", marginLeft: 3 }} onClick={() => setShowMore(-1)}>{t("(voir moins)")}</span>
                                                        : <span style={{ cursor: 'pointer', textDecoration: 'underline', color: "gray", marginLeft: 3 }} onClick={() => setShowMore(row.id)}>{t("(...voir plus)")}</span>}
                                                </> : row.message}</Text>
                                            </CardBody>
                                        </Card>

                                    ))}
                                </AccordionPanel>
                            </AccordionItem>
                        </Accordion>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={onClose}>{t("Fermer")}</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    )
}

export default GestPartenairesSignales