import { Box, Button, Card, CardBody, CardHeader, Flex, Grid, Icon, Image, Text, useColorModeValue } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { FaPlus } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import axiosClient from '../../axios-client';
import { useTranslation } from 'react-i18next';

function GestEvents() {

    const [data, setData] = useState([]);
    const { t } = useTranslation();
   
    const navigate = useNavigate()
    const textColor = useColorModeValue("gray.500", "white");

    const [showGradient, setShowGradient] = useState(-1)

    useEffect(() => {
        axiosClient.get(`/events/getAllEvents/?page_size=${3}&page=${1}`)
            .then((response) => {
                setData(response.data.results);
            })
            .catch((error) => {
                console.error(error);
            });

    }, [])


    return (
        <Box mt="5px" >
            <Flex>
                <Box w="90%" mt={2} mb={5}>
                    <Text fontFamily='Plus Jakarta Display' as="cite" fontSize={{ base: "18px", md: "20px", lg: "25px" }} color="gray"><span onClick={() => navigate('/')} style={{ cursor: 'pointer' }} onMouseEnter={e => e.target.style.textDecoration = "underline"} onMouseLeave={e => e.target.style.textDecoration = "none"} >{t("Accueil")}</span>  / <span> {t("Espace Événements")} </span></Text>
                </Box>
            </Flex>
            <Card bg={'none'} shadow={'none'} p='16px' >
                <CardHeader p='1px 5px' mb='10px'>
                    <Flex  w={"100%"} direction='row' align={'center'} justifyContent={'space-between'}>

                        <Text fontSize={{base:'lg',md :"xl"}} color='gray.500' fontWeight='Bold' >
                            {t("Consultez les dernièrs événements !!")}
                        </Text>
                        {data?.length>0 &&
                            <Button onClick={() => navigate('/liste-evenements')} _active={'none'} background={'none'} _hover={'none'} w={'fit-content'} fontSize={{ base: 'md', md: "lg" }} color='gray.400'  >
                                {t("Voir tous")}
                            </Button>
                        }
                        
                    </Flex>
                </CardHeader>
                <CardBody px='5px'>
                    <Grid

                        templateColumns={{ sm: "1fr", md: "1fr 1fr", xl: "repeat(4, 1fr)" }}
                        templateRows={{ sm: "1fr 1fr 1fr auto", md: "1fr 1fr", xl: "1fr" }}
                        gap='24px'>
                        {data?.length!==0 && data?.map((val, key) => {
                            return (
                                <Flex direction='column' h={"100%"} >


                                    <Box minH={"400px"} maxH={"400px"} display={'flex'} justifyContent={'center'} height={'fit-content'} position='relative' borderRadius='15px' cursor="pointer" onMouseEnter={() => setShowGradient(key)} onMouseLeave={() => setShowGradient(-1)}>
                                        <Image   w={"100%"} minH={"400px"} maxH={"400px"} h={"100%"} objectFit={'cover'} src={val.image} borderRadius='15px' />

                                        {showGradient !== key &&
                                            <Box

                                                w="100%"
                                                h='100%'
                                                position='absolute'
                                                top='0'
                                                borderRadius='15px'
                                                bg='linear-gradient(180deg, rgba(49, 56, 96, 0.16) 0%, rgba(21, 25, 40, 0.88) 70%)'
                                                >
                                                <Flex direction='column' align={'center'} justify={"end"} h="100%">
                                                    <Text px={3} fontSize='xl' color='gray.100' fontWeight='600' mb='10px'>
                                                        {val.title}
                                                    </Text>
                                                    <Text  px={3} fontSize='lg' color={textColor} fontWeight='bold' mb='10px'>
                                                        {val.description.substring(0, 50)} {val.description.length>50 ? "..." : null}
                                                    </Text>
                                                    <Text px={3} fontSize='md' color='gray.100' fontWeight='400' mb='20px'>
                                                        {val.localisation}
                                                    </Text>
                                                    <Flex px={5} mb='20px' w="100%" fontSize='md' justifyContent={"space-between"} color='gray.100' >
                                                       <Text fontSize={"sm"}> {t("De :")} {new Date(val.start_day).toLocaleDateString(t('fr-FR'))}</Text>
                                                       <Text fontSize={"sm"}> {t('À :')} {new Date(val.end_day).toLocaleDateString(t('fr-FR'))}</Text>
                                                    </Flex>
                                                </Flex>
                                            </Box>}
                                    </Box>


                                </Flex>
                            )
                        })}
                        
                        <Button
                            p='0px'
                            bg='transparent'
                            color='gray.500'
                            border='1px solid lightgray'
                            borderRadius='15px'
                            onClick={() => navigate('/ajouter-evenement')}
                            minH={"400px"} maxH={"400px"} h={"100%"}>
                            <Flex direction='column' justifyContent='center' align='center'>
                                <Icon as={FaPlus} fontSize='lg' mb='12px' />
                                <Text fontSize='lg' fontWeight='bold'>
                                    {t("Ajouter un événement")}
                                </Text>
                            </Flex>
                        </Button>
                    </Grid>
                </CardBody>
            </Card>
        </Box>
    )
}

export default GestEvents