import React, { useEffect, useRef, useState } from 'react'
import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton, chakra, Card, CardHeader, CardBody, CardFooter, SimpleGrid, Button, Text, Heading, Avatar, Flex, Box, Icon, InputGroup, InputLeftElement, Input, Select, useDisclosure,
    useColorModeValue,
    Toast,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    IconButton,
    useToast,
    Image
} from '@chakra-ui/react'
import { FaComments } from 'react-icons/fa'
import { IoMdChatboxes, IoMdTrash } from 'react-icons/io'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { SearchIcon } from '@chakra-ui/icons'
import MyPagination from '../../components/MyPagination'
import axiosClient, { linkImage } from '../../axios-client'
import ChatRoom from './ChatRoom'
import emptyChatRoom from '../../assets/images/emptyChatRoom.png'
function AllRooms() {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const [searchTerm, setSearchTerm] = useState('');
    const [loadingData, setLoadingData] = useState(true)
    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const cancelRef = React.useRef()
    const toast = useToast()

    const [currentPage, setCurrentPage] = useState(0);
    const [rooms, setRooms] = useState([])
    const [paginationData, setPaginationData] = useState()
    const [checkLengthData, setCheckLengthData] = useState(false)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = useRef()
    const [deleteId, setDeleteId] = useState(null);

    const [data, setData] = useState({})
    ////////////////////////////////the size of the table//////////////////////////////
    const PAGE_SIZE = 10;
    const [pageSize, setPageSize] = useState(PAGE_SIZE);
    const handlePageSizeChange = (event) => {
        const newSize = parseInt(event.target.value, 10);
        setPageSize(newSize);
        setCurrentPage(0);
    };
    //////////////////////////////////////////////////////////////////////////////////
    const onOpenAlert = () => {
        setIsAlertOpen(true);
    };
    const onCloseAlert = () => {
        setIsAlertOpen(false);
    };

    useEffect(() => {
        axiosClient.get(`/get-room-users/?page_size=${pageSize}&page=${currentPage + 1}&search_term=${searchTerm}`)
            .then((response) => {
                let filteredData = response.data.results;
                setPaginationData(response.data);

                if (filteredData.length === 0) {
                    setCheckLengthData(true)
                }
                else {
                    setRooms(filteredData);
                }
                setLoadingData(false)
            })
            .catch((error) => {
                console.error(error);
                setLoadingData(false)

            });
    }, [searchTerm, pageSize, currentPage])
    function update() {
        axiosClient.get(`/get-room-users/?page_size=${pageSize}&page=${currentPage + 1}&search_term=${searchTerm}`)
            .then((response) => {
                let filteredData = response.data.results;
                setPaginationData(response.data);

                if (filteredData.length === 0) {
                    setCheckLengthData(true)
                }
                else {
                    setRooms(filteredData);
                }
                setLoadingData(false)
            })
            .catch((error) => {
                console.error(error);
                setLoadingData(false)

            });
    }

    const handleDelete = (id) => {
        if (!id) return;

        axiosClient.get(`deleteRoom/${id}/`)
            .then((response) => {

                toast({
                    description: t("La discussion est supprimée avec succès"),
                    status: 'success',
                    variant: 'left-accent',
                    duration: 2000,
                    isClosable: true,
                    position: 'bottom-right'
                })
                update()
            })
            .catch((error) => {
                console.error(error);
            });
        onCloseAlert()
    }
    const chatBorderBg = useColorModeValue('white', 'gray.800');
    const cardbg = useColorModeValue("linear(to-r, gray.300, #e2e8f0b3)", "linear(to-r, gray.700, gray.800)");
    const opacity = useColorModeValue(0.5, 0.1)
    const iconColor = useColorModeValue("gray.50", "gray.500")
    return (
        <Box mt="5px" >
            <Flex flexDirection={{ base: "column", md: 'row' }} mb={5} align={"center"}>
                <Box w={{ base: '100%', md: "50%", lg: "70%" }} mt={2} mb={5}>
                    <Text fontFamily='Plus Jakarta Display' as="cite" fontSize={{ base: "18px", md: "20px", lg: "25px" }} color="gray"><span onClick={() => navigate('/')} style={{ cursor: 'pointer' }} onMouseEnter={e => e.target.style.textDecoration = "underline"} onMouseLeave={e => e.target.style.textDecoration = "none"} >{t('Accueil')}</span>  / <span >{t("Discussions")}</span></Text>
                </Box>
                <InputGroup size="md" w={{ base: '100%', md: "50%", lg: "30%" }}>
                    <InputLeftElement
                        pointerEvents='none'
                        children={<SearchIcon color='gray.300' />}
                    />
                    <Input
                        bg={useColorModeValue("gray.50", "gray.700")}
                        borderRadius="5"
                        placeholder={t("Recherche...")}
                        value={searchTerm}
                        onChange={(e) => {
                            setCurrentPage(0)
                            setSearchTerm(e.target.value)
                        }}

                        sx={{
                            marginLeft: 'auto',
                        }}
                    />  </InputGroup>
            </Flex>
            {(paginationData?.length !== 0) ?
                <>
                    <SimpleGrid mx={{ base: 2, md: 5, lg: 10 }} spacing={5} templateColumns='repeat(auto-fill, minmax(300px, 1fr))'>

                        {rooms.map((room, key) => (

                            <Card
                                shadow={"none"}
                                border={"none"}
                                bg={"transparent"}
                                px={5}
                                h={"140px"}
                            >
                                <Box role="group" position="relative" h="100%">
                                    <CardBody
                                        onClick={(e) => {
                                            onOpen();
                                            setData({
                                                sender: room.user2_details?.role_user === "PARTENAIRE" ? room.user2_details?.id : room.user1_details?.id,
                                                recepteur: room.user2_details?.role_user === "CLIENT" ? room.user2_details?.id : room.user1_details?.id,
                                                room: room.room_name,
                                                image: room.user2_details?.role_user === "CLIENT" ? linkImage + room.user2_details?.image : linkImage + room.user1_details?.image,
                                                name: room.user2_details?.role_user === "CLIENT" ? `${room.user2_details?.first_name} ${room.user2_details?.last_name}` : `${room.user1_details?.first_name} ${room.user1_details?.last_name}`,
                                            });
                                        }}
                                        cursor={"pointer"}
                                        _hover={{ transform: 'scale(1.03)', transition: 'transform 0.3s ease-in-out' }}
                                        shadow={"xl"}
                                        bgGradient={cardbg}
                                        rounded="md"
                                        h={"100%"}
                                    >
                                        <Flex zIndex={99} position={"absolute"} left={-4} flexDirection={"column"} gap={2} top={"50%"} transform={"translateY(-50%)"}>
                                            <Flex align={"center"} gap={3}>
                                                <Avatar bg={'blue.700'} width={"45px"} height={"45px"} borderRadius={"lg"} name={room.user2_details?.role_user === "PARTENAIRE" ? room.user2_details?.company_name : `${room.user2_details?.first_name} ${room.user2_details?.last_name}`} src={linkImage + room.user2_details?.image} />
                                                <Flex>
                                                    <Text>{room.user2_details?.role_user === "PARTENAIRE" ? room.user2_details?.company_name : `${room.user2_details?.first_name} ${room.user2_details?.last_name}`} </Text>
                                                </Flex>
                                            </Flex>
                                            <Flex align={"center"} gap={3}>
                                                <Avatar bg={'gray.500'} width={"45px"} height={"45px"} borderRadius={"lg"} name={room.user1_details?.role_user === "PARTENAIRE" ? room.user1_details?.company_name : `${room.user1_details?.first_name} ${room.user1_details?.last_name}`} src={linkImage + room.user1_details?.image} />
                                                <Flex>
                                                    <Text>{room.user1_details?.role_user === "PARTENAIRE" ? room.user1_details?.company_name : `${room.user1_details?.first_name} ${room.user1_details?.last_name}`} </Text>
                                                </Flex>
                                            </Flex>
                                        </Flex>
                                        <Box right={0} bottom={0} position="absolute" overflow={'hidden'}>
                                            <Icon
                                                overflow={"hidden"}
                                                as={IoMdChatboxes}
                                                h="36"
                                                w="36"
                                                mr="-4"
                                                mb="-8"
                                                color={iconColor}
                                                opacity={opacity}
                                            />
                                        </Box>
                                    </CardBody>
                                    <IconButton
                                        zIndex={999}
                                        aria-label="Delete"
                                        icon={<IoMdTrash size={"16px"} />}
                                        size="sm"
                                        isRound={true}
                                        colorScheme="red"
                                        variant="outline"
                                        position="absolute"
                                        top={2}
                                        right={2}
                                        display="none"
                                        _groupHover={{ display: 'flex' }}
                                        onClick={(e) => {
                                            e.stopPropagation(); // Prevent the card's onClick from triggering
                                            setDeleteId(room.id);
                                            onOpenAlert();
                                        }}
                                    />
                                </Box>
                            </Card>
                        ))}

                    </SimpleGrid>
                  
                    <Flex my={5} flexDirection={{ base: 'column', lg: "row" }} justify="space-between" align="center" w="100%">
                        <Box flex="1">
                            <MyPagination
                                paginationData={paginationData}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                PAGE_SIZE={pageSize}
                            />
                        </Box>

                        <Select w="70px" value={pageSize} onChange={handlePageSizeChange}>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="40">40</option>
                            <option value="50">50</option>
                        </Select>
                    </Flex>
                </>


                :
                !searchTerm ?
                    <Flex mb={'10'} mt={20} gap={2} flexDirection={'column'} h={"100%"} w={"100%"} alignItems={"center"} justifyContent={"center"}>
                        <Text color={'gray.500'} fontWeight={"semibold"}>{t("il n'y a pas encore de discussions.")}</Text>
                        <Image opacity={0.7} w={{ base: "150px", md: "200px", lg: "250px" }} src={emptyChatRoom} />
                    </Flex>
                    :
                    <Flex mb={'10'} mt={20} gap={2} flexDirection={'column'} h={"100%"} w={"100%"} alignItems={"center"} justifyContent={"center"}>
                        <Text color={'gray.500'} fontWeight={"semibold"}> {t("Aucune discussion correspondante n'a été trouvée.")}</Text>
                        {/* <Image opacity={0.7} w={{ base: "150px", md: "200px", lg: "250px" }} src={emptyChatRoom} /> */}
                    </Flex>
                   
                
               

            }
            <Drawer

                size={"lg"}
                isOpen={isOpen}
                placement='right'
                onClose={onClose}
                finalFocusRef={btnRef}
            >
                <DrawerOverlay />
                <DrawerContent bg={chatBorderBg}>
                    <DrawerCloseButton />
                    {/* <DrawerHeader>Create your account</DrawerHeader> */}

                    <DrawerBody>
                        <ChatRoom sender={data.sender} recepteur={data.recepteur} room={data.room} image={data.image} name={data.name} />

                    </DrawerBody>


                </DrawerContent>
            </Drawer>
            <AlertDialog
                closeOnOverlayClick={false}
                isOpen={isAlertOpen}
                leastDestructiveRef={cancelRef}
                onClose={onCloseAlert}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            {t('Supprimer la discussion')}
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            <Text>
                                {t('êtes-vous sûr ? Vous ne pourrez pas annuler cette action ultérieurement.')}
                            </Text>
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button size={"sm"} ref={cancelRef} onClick={() => {
                                onCloseAlert()
                            }}>
                                {t('Annuler')}
                            </Button>
                            <Button size={"sm"} onClick={() => handleDelete(deleteId)} colorScheme='red' ml={3}>
                                {t('Supprimer')}
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </Box>

    )
}

export default AllRooms