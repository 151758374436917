// import logo from '../../assets/img/logo.png';
import { AddIcon, CloseIcon, Icon, ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import { createRef, useState, useEffect } from 'react';
import { useStateContext } from '../../context/ContextProvider';
import { GrAdd, GrFormNextLink, GrFormPreviousLink } from 'react-icons/gr';
// import responsable_img from '../../assets/img/responsableimg.png'
import countryData from '../../assets/dataJson/countries.json';
import Resizer from "react-image-file-resizer";


import {
    Box,
    Select,
    FormControl,
    FormLabel,
    Input,
    Flex,
    InputGroup,
    HStack,
    InputRightElement,
    useColorModeValue,
    Stack,
    useToast,
    Button,
    Heading,
    Text,
    Image,
    Alert,
    AlertIcon,
    Spinner,
    InputLeftElement,
    Avatar,
    AvatarBadge,

} from '@chakra-ui/react';
import axiosClient from '../../axios-client';
import { TfiReload } from 'react-icons/tfi';
import MySelect from "react-select";
import { MdNavigateNext, MdOutlineEmail } from 'react-icons/md';
import { FaPencilAlt } from 'react-icons/fa';
// import MapContainer from './MapContainer';
import { useTranslation } from 'react-i18next';
import makeAnimated from 'react-select/animated';

const linkStyle = {
    color: '#3C8DBC',
    textDecoration: "underline"
}


export default function AddPartenaire() {
    const { t } = useTranslation();
    const animatedComponents = makeAnimated();
    const toast = useToast()
    const navigate = useNavigate()

    const inputColor = useColorModeValue("gray.300", "gray.600")
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState({});

    const emailRegex = /^[a-zA-Z][a-zA-Z0-9._-]*@[a-zA-Z]+(?:-[a-zA-Z]+)?\.[a-zA-Z]{2,}$/;
    const codepRegex = /^(\d{3})$|(\d{6})$|([A-Z]\d{4}[A-Z]{3})$|(\d{4})$|(\d{4})$|(?:FI)*(\d{5})$|(?:AZ)*(\d{4})$|(\d{5})$|(?:BB)*(\d{5})$|(\d{4})$|(\d{4})$|(\d{4})$|(\d{3}\d?)$|([A-Z]{2}\d{2})$|([A-Z]{2}\d{4})$|(\d{8})$|(\d{6})$|([ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ]) ?(\d[ABCEGHJKLMNPRSTVWXYZ]\d)$|(\d{4})$|(\d{7})$|(\d{6})$|(\d{4})$|(?:CP)*(\d{5})$|(\d{4})$|(\d{4})$|(\d{4})$|(\d{5})$|(\d{5})$|(?:FI)*(\d{5})$|(\d{5})$|(\d{4})$|(\d{6})$|(?:SEOUL)*(\d{6})$|(\d{5})$|(\d{6})$|(\d{5})$|(\d{4})$|(\d{5})$|(\d{5})$|(\d{10})$|(\d{3})$|(\d{5})$|(\d{5})$|([A-Z]\d{2}[A-Z]{2})|([A-Z]\d{3}[A-Z]{2})|([A-Z]{2}\d{2}[A-Z]{2})|([A-Z]{2}\d{3}[A-Z]{2})|([A-Z]\d[A-Z]\d[A-Z]{2})|([A-Z]{2}\d[A-Z]\d[A-Z]{2})|(GIR0AA)$|(\d{5})$|(\d{7})$|([A-Z]\d{2}[A-Z]{2})|([A-Z]\d{3}[A-Z]{2})|([A-Z]{2}\d{2}[A-Z]{2})|([A-Z]{2}\d{3}[A-Z]{2})|([A-Z]\d[A-Z]\d[A-Z]{2})|([A-Z]{2}\d[A-Z]\d[A-Z]{2})|(GIR0AA)$|(\d{5})$|(\d{4}(\d{4})?)$|(\d{4})$|(\d{5})$|(\d{6})$|(\d{5})$|(\d{6})$|(?:SEOUL)*(\d{6})$|(\d{5})$|(\d{5})$|(\d{5})$|(\d{6})$|(\d{4})$|(\d{7})$|(97500)$|(\d{9})$|(\d{7})$|(96940)$|(\d{4})$|((97|98)(4|7|8)\d{2})$|(\d{6})$|(\d{6})$|(\d{6})$|(\d{5})$|(\d{5})$|(?:SE)*(\d{5})$|(\d{6})$|(STHL1ZZ)$|(?:SI)*(\d{4})$|(\d{5})$|4789\d$|(\d{5})$|(?:CP)*(\d{4})$|([A-Z]\d{3})$|(TKCA 1ZZ)$|(\d{5})$|(\d{6})$|(\d{6})$|(\d{4})$|(\d{5})$|(\d{5})$|(986\d{2})$|(\d{5})$|(\d{4})$|(\d{5})$|(\d{5})$|([A-Z]{1,2}\d[A-Z\d]? \d[A-Z]{2})$/i;

    const [first_name, setFirstName] = useState("")
    const [last_name, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [rue, setRue] = useState("")
    const [ville, setVille] = useState("")
    // const password = createRef()
    const [codePostal, setCodePostal] = useState("")
    const [pays, setPays] = useState([])
    const [tel, setTel] = useState()

    // const confirmPassword = createRef()
    const [confirmPassword, setConfirmPassword] = useState('');

    const [pwd, setPwd] = useState('');

    const [message, setMessage] = useState('')
    const [showPassword, setShowPassword] = useState(false);
    const [showEntreprise, setShowEntreprise] = useState(false);

    const [selectedSexe, setSelectedSexe] = useState('')

    const { user, isLoading, setIsLoading } = useStateContext()

    //entreprise variables 
    const [name, setName] = useState("")
    const [descirption, setDescription] = useState("")

    const [selectedImage, setSelectedImage] = useState()
    const [selectedImg, setSelectedImg] = useState()

    const [image, setImage] = useState()


    const [paysOptions, setPaysOptions] = useState([
        { value: '', label: '' },
    ]);
    const [cityOptions, setCityOptions] = useState([
        { value: '', label: '' },
    ]);
    const [selectedOptions, setSelectedOptions] = useState('');
    const [selectedCityOptions, setSelectedCityOptions] = useState(null);

    const [options, setOptions] = useState([
        { value: '', label: '' },
    ]);
    const [sCatOptions, setSCatOptions] = useState([
        { value: '', label: '', category: '' },
    ]);

    const [listSelects, setListSelects] = useState([
        {
            cats: '',
            subCats: ''
        }
    ])

    const resizeFile = (file) => {
        return new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                300,
                300,
                "png",
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "blob"
            );
        });
    };

    useEffect(() => {
        const Mycountries = [];
        const data = countryData;
        for (let index = 0; index < data.countries.length; index++) {
            Mycountries.push({
                value: data.countries[index].value,
                label: data.countries[index].label.substring(5, 50)
            });
        }
        setCountries(Mycountries);

        if (selectedImg) {
            setImage(URL.createObjectURL(selectedImg))
        }
        else {
            setSelectedImg()
        }


        axiosClient.get('/categories/')
            .then((response) => {
                const catsOptions = response.data.filter(e => e.pays.includes(selectedOptions.value)).map(cat => ({
                    value: cat.id,
                    label: cat.name,
                }));

                setOptions([
                    ...catsOptions,
                ]);
            })
            .catch((error) => {
                console.error(error);
            });

        axiosClient.get('/subCategories/')
            .then((response) => {

                const subCatsOptions = response.data.map(cat => ({
                    value: cat.id,
                    label: cat.nom_subCategory,
                    category: cat.category
                }));

                console.log('sous cat : ', subCatsOptions);
                // data.push(subCatsOptions)
                setSCatOptions([
                    ...subCatsOptions,
                ]);
            })
            .catch((error) => {
                console.error(error);
            });

        axiosClient.get('pays/')
            .then(({ data }) => {
                setPays(data);

                const paysOptions = data.map(pay => ({
                    value: pay.id,
                    label: pay.nom_pays,
                }));

                setPaysOptions([
                    ...paysOptions,
                ]);
            })
            .catch((error) => {
                console.error('Error fetching pays data:', error);
            });

        axiosClient.get('villes/')
            .then(({ data }) => {
                console.log("option", selectedOptions);
                const CityOptions = data.filter(e => e.pays === parseInt(selectedOptions.value)).map(city => ({
                    value: city.id,
                    label: city.nom_ville,
                }));

                setCityOptions([
                    ...CityOptions,
                ]);
            })
            .catch((error) => {
                console.error('Error fetching cities data:', error);
            });

    }, [selectedImage, selectedOptions, selectedCityOptions]);


    const handleSexeChange = (ev) => {
        setSelectedSexe(ev.target.value)
    }

    const handlePaysSelectChange = (selectedOptions) => {
        setSelectedOptions(selectedOptions);
    };
    const handleCitySelectChange = (selectedOptions) => {
        setSelectedCityOptions(selectedOptions);
    };



    // categories and subcategories selection area start
    const handleSCatSelectChange = (index, e) => {
        setListSelects(prevList => {
            const updatedList = [...prevList];
            updatedList[index] = { ...updatedList[index], subCats: e };
            return updatedList;
        });
    };
    const handleSelectChange = (index, e) => {
        setListSelects(prevList => {
            const updatedList = [...prevList];
            updatedList[index] = { ...updatedList[index], cats: e, subCats: '' };
            return updatedList;
        });
    }

    function getCategories() {
        return (
            <>
                {listSelects.map((val, index) => (
                    <Flex mt={2} justify={"space-between"} pb={3} key={index} gap={3} align={'center'} w={"100%"}>
                        <Box w={"50%"}>
                            <MySelect
                                name='cats'
                                value={val.cats}
                                onChange={(e) => {
                                    handleSelectChange(index, e)
                                }}
                                options={options}
                                closeMenuOnSelect={true}
                                components={animatedComponents}
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        backgroundColor: 'transparent',
                                    }),
                                    menu: (provided) => ({
                                        ...provided,
                                        textAlign: 'left',
                                        backgroundColor: colorbg,
                                        cursor: "pointer"
                                    }),
                                    multiValueLabel: (provided) => ({
                                        ...provided,
                                        textAlign: 'left',
                                        overflow: 'visible',
                                        backgroundColor: multiLabel,
                                        color: multitext,
                                        cursor: "pointer"
                                    }),
                                    multiValue: (provided) => ({
                                        ...provided,
                                        textAlign: 'left',
                                        backgroundColor: multiLabel,
                                        color: multitext,
                                        cursor: "pointer"
                                    }),

                                    multiValueRemove: (provided) => ({
                                        ...provided,
                                        backgroundColor: multiLabel,
                                        color: multitext,
                                    }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        color: txtcolor,
                                        backgroundColor: state.isSelected ? colorbg : 'inherit',
                                        '&:hover': {
                                            backgroundColor: colorbghover,
                                        },
                                        cursor: "pointer"
                                    }),
                                    singleValue: (provided) => ({
                                        ...provided,
                                        color: txtcolor
                                    }),
                                }}
                            />
                        </Box>
                        <Box w={"50%"}>
                            <MySelect
                                name='subCats'
                                value={val.subCats}
                                onChange={(e) => handleSCatSelectChange(index, e)}
                                options={sCatOptions.filter(e => e.category === val.cats?.value)}
                                closeMenuOnSelect={true}
                                components={animatedComponents}
                                isMulti
                                isClearable
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        backgroundColor: 'transparent',

                                    }),
                                    menu: (provided) => ({
                                        ...provided,
                                        textAlign: 'left',
                                        backgroundColor: colorbg,
                                        cursor: "pointer"
                                    }),
                                    multiValueLabel: (provided) => ({
                                        ...provided,
                                        textAlign: 'left',
                                        overflow: 'visible',
                                        backgroundColor: multiLabel,
                                        color: multitext,
                                        cursor: "pointer"
                                    }),
                                    multiValue: (provided) => ({
                                        ...provided,
                                        textAlign: 'left',
                                        backgroundColor: multiLabel,
                                        color: multitext,
                                        cursor: "pointer"
                                    }),

                                    multiValueRemove: (provided) => ({
                                        ...provided,
                                        backgroundColor: multiLabel,
                                        color: multitext,
                                    }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        color: txtcolor,
                                        backgroundColor: state.isSelected ? colorbg : 'inherit',
                                        '&:hover': {
                                            backgroundColor: colorbghover,
                                        },
                                        cursor: "pointer"
                                    }),
                                    singleValue: (provided) => ({
                                        ...provided,
                                        color: txtcolor
                                    }),
                                }}
                            />
                        </Box>
                        {(listSelects.length > 1) &&
                            <CloseIcon color={'red'} className='btnclose' fontSize="xs" cursor="pointer" onClick={() => removeSelects(index)} />
                        }
                        <AddIcon fontSize="sm" cursor="pointer" onClick={AddSelects} />
                    </Flex>
                ))}



            </>
        );
    }
    const AddSelects = () => {
        setListSelects(prevList => [...prevList, { cats: '', subCats: '' }]);
    };
    const removeSelects = (i) => {
        console.log(i);
        const list = [...listSelects];
        list.splice(i, 1);
        setListSelects(list);
    };
    // categories and subcategories selection area end

    const handleCountryChange = (e) => {
        setSelectedCountry(countries.find(
            (country) => country.value === e.target.value
        ))

    };


    ////////////////////////// VALIDATION DE MOT DE PASSE /////////////////////////////////////////
    const [passwordStrength, setPasswordStrength] = useState('');
    const [passwordColor, setPasswordColor] = useState('');

    const checkPasswordStrength = () => {
        const specialchar = /[@#$%^&+=!*_|èàç()/."';:,?ù]/;
        const minLengthRegex = /^.{8,}$/;
        const startLength = /^.{2,}$/;
        const digitRegex = /\d/;
        const lowercaseRegex = /[a-z]/;
        const uppercaseRegex = /[A-Z]/;

        let missingRequirements = [];

        if (!specialchar.test(pwd)) {
            missingRequirements.push(t("caractère spécial"));
        } else if (!lowercaseRegex.test(pwd)) {
            missingRequirements.push(t("minuscule"));
        } else if (!uppercaseRegex.test(pwd)) {
            missingRequirements.push(t("majuscule"));
        } else if (!digitRegex.test(pwd)) {
            missingRequirements.push(t("chiffres"));
        } else if (!minLengthRegex.test(pwd)) {
            missingRequirements.push(t("longueur minimale de 8 caractères"));
        }

        if (missingRequirements.length > 0) {
            const missingText = `${t("Vous avez besoin de")} ${missingRequirements.join(", ")} ${t("dans votre mot de passe.")}`;
            setPasswordStrength(missingText);
            setPasswordColor('red.500');
        } else {
            setPasswordStrength(t('Le mot de passe est correct!'));
            setPasswordColor('green');
        }
    }

    useEffect(() => {
        checkPasswordStrength();
        console.log("my list", listSelects);
    }, [pwd, listSelects]);
    // check the password complexity
    const isPasswordValid = (password) => {
        const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=!*_|èàç()/."';:,?ù])[0-9a-zA-Z@#$%^&+=!*_|èàç()/."';:,?ù]{8,}$/;
        const specialchar = /[@#$%^&+=!*_|èàç()/."';:,?ù]/;
        const minLengthRegex = /^.{8,}$/;
        const digitRegex = /\d/;
        const lowercaseRegex = /[a-z]/;
        const uppercaseRegex = /[A-Z]/;

        let errors = [];

        if (!minLengthRegex.test(password)) {
            errors.push(t('Le mot de passe doit comporter au moins 8 caractères.'));
        }

        if (!digitRegex.test(password)) {
            errors.push(t('Le mot de passe doit contenir au moins un chiffre.'));
        }

        if (!lowercaseRegex.test(password)) {
            errors.push(t('Le mot de passe doit contenir au moins une lettre minuscule.'));
        }

        if (!uppercaseRegex.test(password)) {
            errors.push(t('Le mot de passe doit contenir au moins une lettre majuscule.'));
        }
        if (!specialchar.test(password)) {
            errors.push(t('Le mot de passe doit contenir au moins un caractère spécial (@#$%^&+=).'));
        }
        if (password.length > 20) {
            errors.push(t('Le mot de passe ne doit pas dépasser 20 caractères.'));
        }

        if (errors.length > 0) {
            setMessage(errors[0]);
            return false;
        }

        return passwordRegex.test(password);
    };

    const handleChangeImage = async (e) => {
        try {
            const file = e.target.files[0];
            setSelectedImg(file)
            const blobImage = await resizeFile(file);

            const fileName = file.name; // Use the original filename
            const imageFile = new File([blobImage], fileName, { type: blobImage.type });

            setSelectedImage(imageFile);
        } catch (err) {
            console.log(err);
        }
    }

    const [disableInputConfirm, setDisableInputConfirm] = useState(false)
    const [generetedPwd, setGeneretedPwd] = useState()
    const generatePassword = () => {
        const length = 10; // Length of the generated password
        const uppercaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const lowercaseLetters = 'abcdefghijklmnopqrstuvwxyz';
        const numbers = '0123456789';
        const specialCharacters = '[@#$%^&+=!*_|èàç()/.";:,?ù]';

        let newPassword = '';
        let characterSet = '';

        // Include at least one character from each set
        newPassword += uppercaseLetters[Math.floor(Math.random() * uppercaseLetters.length)];
        newPassword += lowercaseLetters[Math.floor(Math.random() * lowercaseLetters.length)];
        newPassword += numbers[Math.floor(Math.random() * numbers.length)];
        newPassword += specialCharacters[Math.floor(Math.random() * specialCharacters.length)];

        characterSet = uppercaseLetters + lowercaseLetters + numbers + specialCharacters;

        // Generate remaining characters randomly
        for (let i = newPassword.length; i < length; i++) {
            newPassword += characterSet[Math.floor(Math.random() * characterSet.length)];
        }

        setPwd(newPassword);
        setConfirmPassword(newPassword);

        // confirmPassword.current.value = newPassword
        setGeneretedPwd(newPassword)
        setDisableInputConfirm(true)

    };
    const isEmpty = () => {
        return !email.trim() || !pwd.trim() || !selectedOptions.value
    };
    const isEmptyEntreprise = () => {
        return !name.trim() || !descirption.trim() || !selectedImage || !listSelects[0].cats
    };
    const checkForm = () => {

        if ((ville && ville.trim() === "") || (codePostal && codePostal.trim() === "") || (rue && rue.trim() === "")) {
            window.scrollTo(0, 0)
            setMessage(t('Veuillez remplir les champs correctement'))
            return
        }
        // setMessage("")

        else if (pwd !== confirmPassword) {
            setMessage(t("Veuillez confirmer votre mot de passe"));
        } else if (passwordStrength !== 'Fort' && pwd.length > 0 && !isPasswordValid(pwd)) {
            return;
        }
        else if (!emailRegex.test(email)) {
            setMessage(t("Veuillez entrer un email valide."));
            setIsLoading(false)
            return;
        }
        else if (codePostal && !codepRegex.test(codePostal)) {
            setMessage(t("Veuillez entrer un code postal valide."));
            return;
        }
        setShowEntreprise(true)
        setMessage('')

    }

    const onSubmit = ev => {
        ev.preventDefault()

        let categories = []
        let subCategories = []
        let sCatsData = []

        listSelects.map((row) => (
            categories.push(row.cats?.value),
            subCategories.push(row.subCats)

        ))

        for (let index = 0; index < subCategories.length; index++) {
            for (let j = 0; j < subCategories[index].length; j++) {
                sCatsData.push(subCategories[index][j]?.value)
            }
        }
        const selectedCitiesIds = selectedCityOptions ? selectedCityOptions.map(option => option.value) : [];
            const formdata = new FormData()
            formdata.append("email", email )
            formdata.append("code_postal", codePostal)
            formdata.append("rue", rue)
            formdata.append("tel", tel)
            formdata.append("added_by", user.first_name + ' ' + user.last_name)
            formdata.append("password", pwd)
            formdata.append("etat", "True")
            formdata.append("image", selectedImage)
            formdata.append("company_name", name)
            formdata.append("pays_partenaire", selectedOptions.value);
            if (selectedCitiesIds.length > 0) {
                for (let index = 0; index < selectedCitiesIds.length; index++) {
                    formdata.append("ville_partenaire", selectedCitiesIds[index]);
                }
            }

            for (let index = 0; index < categories.length; index++) {
                formdata.append("category", categories[index]);
            }
            if (sCatsData.length > 0) {
                for (let index = 0; index < sCatsData.length; index++) {
                    formdata.append("sub_cat", sCatsData[index]);
                }
            }

            formdata.append("company_description", descirption)

            axiosClient.post('/partenaires/', formdata)
                .then((response) => {
                    toast({
                        description: t("le partenaire est ajouté avec succès"),
                        status: 'success',
                        duration: 2000,
                        isClosable: true,
                        position: 'bottom-right',
                        variant: 'left-accent',
                    })
                    navigate('/partenaires')

                })
                .catch((err) => {
                    const response = err.response;
                    setMessage(response.data)
                })

    }

    // const [countries, setCountries] = useState([]);
    // const [selectedCountry, setSelectedCountry] = useState({});

    // useEffect(() => {
    //     const Mycountries = [];
    //     const data = countryData;
    //     for (let index = 0; index < data.countries.length; index++) {
    //         Mycountries.push({
    //             value: data.countries[index].value,
    //             label: data.countries[index].label.substring(5, 50)
    //         });
    //     }
    //     setCountries(Mycountries);
    // }, []);


    const colorbg = useColorModeValue('white', '#2d3748')
    const multiLabel = useColorModeValue('#e6e6e6', '#4e536c')

    const multitext = useColorModeValue('black', 'white')


    const txtcolor = useColorModeValue('black', 'white')
    const colorbghover = useColorModeValue('#e2e8f0', '#171923')

    return (
        <Box>
            <Box w={"100%"} mt={3} mb={2}>
                <Text as="cite" fontSize={{ base: "18px", md: "20px", lg: "22px" }} color="gray"><span onClick={() => navigate('/')} style={{ cursor: 'pointer' }} onMouseEnter={e => e.target.style.textDecoration = "underline"} onMouseLeave={e => e.target.style.textDecoration = "none"} >{t("Accueil")}</span>  / <span onClick={() => navigate('/admins')} style={{ cursor: 'pointer' }} onMouseEnter={e => e.target.style.textDecoration = "underline"} onMouseLeave={e => e.target.style.textDecoration = "none"} >{t("Partenaires")}</span> / <span> {t("Ajouter partenaire")}</span></Text>
            </Box>
            <Flex
                direction={{ base: 'column', md: 'row' }}
                align={{ base: 'stretch', md: 'center' }}
                justify={"center"}
                p={5}
            >

                <Stack
                    bg={useColorModeValue('white', 'gray.700')}
                    rounded={'lg'}
                    p={6}
                    my={5}
                    w={{ base: '100%', md: '100%', lg: '70%', sm: '100%' }}
                >

                    {showEntreprise ?
                        <>
                            <Box p={5} rounded={'lg'} w="100%" maxW="full"
                                align={'center'}
                                justify={'center'}
                            >
                                <Text pb={5} fontSize={"xl"} fontWeight={"semibold"}>{t("Information entreprise")}</Text>
                                <Stack spacing={2} w={{ base: '100%', md: '100%', lg: '100%', sm: '100%' }}
                                >
                                    <Flex w={"100%"} justify={"center"} >
                                        <Avatar mb={3} me={{ md: '22px' }} mr={{ base: 5, sm: 0, md: 5 }} src={image} w={{ base: "70px", md: "200px" }} h={{ base: "70px", md: "150px" }} borderRadius='15px'>
                                            <label htmlFor="imageInput">
                                                <input
                                                    id="imageInput"
                                                    type="file"
                                                    accept="image/*"
                                                    style={{ display: 'none' }}
                                                    onChange={handleChangeImage}
                                                />
                                                <AvatarBadge
                                                    cursor='pointer'
                                                    borderRadius='8px'
                                                    border='transparent'
                                                    bg='linear-gradient(138.78deg, rgba(6, 11, 40, 0.94) 17.44%, rgba(10, 14, 35, 0.49) 93.55%, rgba(10, 14, 35, 0.69) 93.55%)'
                                                    boxSize='26px'
                                                    backdropFilter='blur(120px)'>
                                                    <Icon h='12px' w='12px' color='#fff' as={FaPencilAlt} />
                                                </AvatarBadge>
                                            </label>
                                        </Avatar>
                                    </Flex>

                                    <HStack spacing={2}>
                                        <Box w="100%">
                                            <FormControl id="name" isRequired >
                                                <FormLabel ml={0.5}>{t("Nom entreprise")}</FormLabel>
                                                <Input value={name} onChange={(e) => setName(e.target.value)} name="name" type="text" placeholder={t("Tapez le nom d'entreprise")} maxLength={50} />
                                            </FormControl>
                                        </Box>

                                    </HStack>
                                    <HStack spacing={2}>
                                        <Box w="100%">
                                            <FormControl id="descirption" isRequired >
                                                <FormLabel ml={0.5}>Description</FormLabel>
                                                <Input value={descirption} onChange={(e) => setDescription(e.target.value)} name="descirption" type="text" placeholder="Tapez la description" maxLength={250} />
                                            </FormControl>
                                        </Box>

                                    </HStack>
                                    <Flex flexDirection={'column'} w={"100%"}>
                                        <FormControl id="activity"  >
                                            <FormLabel ml={0.5}>Activités</FormLabel>
                                        </FormControl>
                                        {getCategories()}


                                    </Flex>
                                    
                                    <Stack direction={['column', 'row']} spacing={10} pt={4} justifyContent="space-between">
                                        <Button
                                            type="submit"
                                            onClick={() => setShowEntreprise(false)}
                                            isLoading={isLoading}
                                            colorScheme="gray"
                                            leftIcon={<GrFormPreviousLink />}
                                        >
                                            {t("Précédent")}
                                        </Button>
                                        {
                                            isEmptyEntreprise() ? (
                                                <Button
                                                    type="submit"
                                                    onClick={onSubmit}
                                                    isLoading={isLoading}
                                                    colorScheme="gray"
                                                    isDisabled
                                                    leftIcon={<GrAdd />}
                                                >
                                                    {isLoading ? <Spinner size="sm" /> : t("Ajouter")}
                                                </Button>
                                            ) : (
                                                <Button
                                                    type="submit"
                                                    onClick={onSubmit}
                                                    isLoading={isLoading}
                                                    colorScheme="gray"
                                                    leftIcon={<GrAdd />}
                                                >
                                                    {isLoading ? <Spinner size="sm" /> : t("Ajouter")}
                                                </Button>
                                            )
                                        }
                                    </Stack>

                                </Stack>
                            </Box>


                        </> :
                        <>
                            {message &&
                                <Alert fontSize={"sm"} padding={2} status='error' rounded="md">
                                    <AlertIcon />
                                    {message}
                                </Alert>
                            }
                            <Box p={5} rounded={'lg'} w="100%" maxW="full"
                                align={'center'}
                                justify={'center'}
                            >
                                <Stack spacing={2} w={{ base: '100%', md: '100%', lg: '100%', sm: '100%' }}
                                >
                                    
                                    <HStack mb={2} spacing={2}>
                                        <Box w="50%">
                                            <FormControl id="email" isRequired >
                                                <FormLabel ml={0.5}>{t("Adresse email")}</FormLabel>
                                                <Input value={email} onChange={(e) => setEmail(e.target.value)} name="email" type="email" placeholder={t("Tapez l'adresse email")} maxLength={50} />
                                            </FormControl>
                                        </Box>
                                        <Box w="50%">
                                            <FormControl id="rue">
                                                <FormLabel ml={0.5}>{t('Telephone')}</FormLabel>
                                                <Input value={tel} onChange={(e) => setTel(e.target.value)} name="tel" type="number" placeholder={t('Tapez le numéro de téléphone')} maxLength={50} />
                                            </FormControl>

                                        </Box>

                                    </HStack>
                                    <Text align={'left'} fontSize="sm" color='yellow.600'>{t('Le mot de passe doit comporter au moins 8 caractères et contenir au moins un chiffre, une lettre minuscule , une lettre majuscule et un chiffre special.')}</Text>

                                    <HStack>
                                        <Box w="50%">
                                            <FormControl id="password" isRequired>
                                                <FormLabel ml={0.5}>{t('Mot de passe')}</FormLabel>
                                                <InputGroup>
                                                    <InputLeftElement onClick={generatePassword} as={'Button'} >
                                                        <TfiReload color='gray.300' />
                                                    </InputLeftElement>
                                                    <Input value={pwd} name="password" type={showPassword ? 'text' : 'password'} onChange={(e) => setPwd(e.target.value)} onCopy={(e) => e.preventDefault()} placeholder={t('Tapez le mot de passe')} />

                                                    <InputRightElement h={'full'}>

                                                        <Button
                                                            variant={'ghost'}
                                                            onClick={() =>
                                                                setShowPassword((showPassword) => !showPassword)
                                                            }>
                                                            {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                                                        </Button>
                                                    </InputRightElement>
                                                </InputGroup>


                                            </FormControl>

                                        </Box>

                                        <Box w="50%">
                                            <FormControl id="password_confirmation" isRequired>
                                                <FormLabel ml={0.5}>{t('Confirmer le mot de passe')}</FormLabel>
                                                <InputGroup>
                                                    {(disableInputConfirm && pwd === generetedPwd) ?
                                                        <Input onChange={(e) => setConfirmPassword(e.target.value)} name="confirmPassword" type="password" placeholder={t('Confirmez le mot de passe')} disabled />
                                                        :
                                                        <Input onChange={(e) => setConfirmPassword(e.target.value)} name="confirmPassword" type="password" placeholder={t('Confirmez le mot de passe')} />
                                                    }
                                                </InputGroup>
                                            </FormControl>
                                        </Box>
                                    </HStack>
                                    {pwd.length > 0 ?
                                        <Text align="left" justify="left" fontSize="sm" mt={2} color={passwordColor}>{`${passwordStrength}`}</Text>

                                        : ""}




                                    <Text py='3' fontWeight={"bold"} align={'left'} fontSize="xl" >{t("Adresse")}  :</Text>


                                    <HStack spacing={2}>

                                        <Box w="50%">
                                            <FormControl id="pays" isRequired>
                                                <FormLabel ml={0.5}>{t("Pays")}</FormLabel>
                                                {/* <Input  onChange={(e) => setPays(e.target.value)} name="pays" type="text" placeholder='Tapez le pays' maxLength={30} /> */}
                                                {/* <Select
                                                    borderColor={inputColor}

                                                    id="pays"
                                                    name="pays"
                                                    defaultValue={selectedCountry}
                                                    onChange={handleCountryChange}
                                                    isSearchable
                                                    bg={colorbg}
                                                    color={txtcolor}
                                                    _focus={{ bg: colorbg, borderColor: "transparent" }}
                                                >
                                                    <option>-- {t("Choisir un pays")} --</option>
                                                    {countries.sort((a, b) => a.label.localeCompare(b.label)).map((country) => (
                                                        <option
                                                            key={country.value}
                                                            value={country.value}
                                                            bg={colorbg}
                                                            color={txtcolor}
                                                            _hover={{ bg: colorbghover, cursor: "pointer" }}
                                                        >
                                                            {country.label}
                                                        </option>
                                                    ))}
                                                </Select> */}
                                                <MySelect
                                                    defaultValue={selectedOptions}
                                                    onChange={handlePaysSelectChange}
                                                    options={paysOptions}
                                                    closeMenuOnSelect={true}
                                                    components={animatedComponents}
                                                    styles={{
                                                        control: (baseStyles, state) => ({
                                                            ...baseStyles,
                                                            backgroundColor: 'transparent',
                                                        }),
                                                        menu: (provided) => ({
                                                            ...provided,
                                                            textAlign: 'left',
                                                            backgroundColor: colorbg,
                                                            cursor: "pointer"
                                                        }),

                                                        option: (provided, state) => ({
                                                            ...provided,
                                                            color: txtcolor,
                                                            backgroundColor: state.isSelected ? colorbg : 'inherit',
                                                            '&:hover': {
                                                                backgroundColor: colorbghover,
                                                            },
                                                            cursor: "pointer"
                                                        }),
                                                        singleValue: (provided) => ({
                                                            ...provided,
                                                            color: txtcolor
                                                        }),
                                                    }}
                                                />
                                            </FormControl>
                                        </Box>
                                        <Box w="50%">
                                            <FormControl id="ville">
                                                <FormLabel ml={0.5}>{t("Ville")}</FormLabel>
                                                {/* <Input value={ville} onChange={(e) => setVille(e.target.value)} name="ville" type="text" placeholder={t('Tapez la ville')} maxLength={30} /> */}
                                                <MySelect
                                                    defaultValue={selectedCityOptions}
                                                    onChange={handleCitySelectChange}
                                                    options={cityOptions}
                                                    closeMenuOnSelect={false}
                                                    components={animatedComponents}
                                                    isMulti

                                                    styles={{
                                                        control: (baseStyles, state) => ({
                                                            ...baseStyles,
                                                            backgroundColor: 'transparent',
                                                        }),
                                                        menu: (provided) => ({
                                                            ...provided,
                                                            textAlign: 'left',
                                                            backgroundColor: colorbg,
                                                            cursor: "pointer"
                                                        }),
                                                        multiValueLabel: (provided) => ({
                                                            ...provided,
                                                            textAlign: 'left',
                                                            overflow: 'visible',
                                                            backgroundColor: multiLabel,
                                                            color: multitext,
                                                            cursor: "pointer"
                                                        }),
                                                        multiValue: (provided) => ({
                                                            ...provided,
                                                            textAlign: 'left',
                                                            backgroundColor: multiLabel,
                                                            color: multitext,
                                                            cursor: "pointer"
                                                        }),

                                                        multiValueRemove: (provided) => ({
                                                            ...provided,
                                                            backgroundColor: multiLabel,
                                                            color: multitext,
                                                        }),
                                                        option: (provided, state) => ({
                                                            ...provided,
                                                            color: txtcolor,
                                                            backgroundColor: state.isSelected ? colorbg : 'inherit',
                                                            '&:hover': {
                                                                backgroundColor: colorbghover,
                                                            },
                                                            cursor: "pointer"
                                                        }),
                                                        singleValue: (provided) => ({
                                                            ...provided,
                                                            color: txtcolor
                                                        }),
                                                    }}
                                                />
                                            </FormControl>
                                        </Box>
                                    </HStack>
                                    <HStack spacing={2}>
                                        <Box w="50%">
                                            <FormControl id="rue">
                                                <FormLabel ml={0.5}>{t("Rue")}</FormLabel>
                                                <Input value={rue} onChange={(e) => setRue(e.target.value)} name="rue" type="text" placeholder={t('Tapez la rue')} maxLength={50} />
                                            </FormControl>

                                        </Box>
                                        <Box w="50%">
                                            <FormControl id="codePostal" >
                                                <FormLabel ml={0.5}>{t("Code postal")}</FormLabel>
                                                <Input value={codePostal} onChange={(e) => setCodePostal(e.target.value)} name="codePostal" type="text" placeholder={t('Tapez le code postal')} maxLength={15} />
                                            </FormControl>
                                        </Box>
                                    </HStack>
                                    <Stack direction={['column', 'row']} spacing={10} pt={2} justifyContent="end">
                                        {
                                            isEmpty() ? (
                                                <Button
                                                    type="submit"
                                                    // onClick={onSubmit}
                                                    isLoading={isLoading}
                                                    colorScheme="gray"
                                                    isDisabled
                                                    leftIcon={<GrFormNextLink />}
                                                >
                                                    {isLoading ? <Spinner size="sm" /> : t("Suivant")}
                                                </Button>
                                            ) : (
                                                <Button
                                                    type="submit"
                                                    onClick={checkForm}
                                                    isLoading={isLoading}
                                                    colorScheme="gray"
                                                    rightIcon={<GrFormNextLink />}
                                                >
                                                    {isLoading ? <Spinner size="sm" /> : t("Suivant")}
                                                </Button>
                                            )
                                        }
                                    </Stack>
                                </Stack>
                            </Box>
                        </>}

                </Stack>
                {/* <Stack w="50%"
                    justify="center"
                    alignItems="center"
                    p={5}
                >
                    <Image display={{ base: 'none', md: 'flex' }} src={"responsable_img"} />
                </Stack> */}
            </Flex>
        </Box>

    )
}